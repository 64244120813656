import * as firebase from "firebase/app";
import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { IHonorUProfile } from "../../models/honoru";
import { StorageConfig } from "../../laco-common/firebase/storage";
import { IImage, VideoMedia } from "../../models/Image";
import { convertDateFromFirebase } from "../../utils/date-utils";
import { FileType } from "../../laco-common/base/models/IFile";

const honorUFireStoreConfig = {
	apiKey: "AIzaSyCiZwl3qjgGIUUZ9BzHhKYZE5nqnR-h8Nw",
	authDomain: "memorials-site.firebaseapp.com",
	databaseURL: "https://memorials-site.firebaseio.com",
	projectId: "memorials-site",
	storageBucket: "memorials-site.appspot.com",
	messagingSenderId: "451134093234",
};

function getThumbFilename(size: number, filename: string): string {
	if (filename?.startsWith("thumb@")) return filename;
	return `thumb@${size}_${filename}`;
}

export const storageConfig: StorageConfig = new StorageConfig("memorials-site");
export const getProfileUrl = (profile: IHonorUProfile): string =>
	storageConfig.getFilePath2("Personnel Images", profile.id, "profile", profile.profileImg);

const firebaseHonorUApp = firebase.initializeApp(honorUFireStoreConfig, "honoru");

export enum HonorUActionTypes {
	LOAD_PROFILES = "[honoru] LOAD_PROFILES",
	LOAD_HEADLINE = "[honoru] LOAD_HEADLINE",
	LOAD_FEATURED = "[honoru] LOAD_FEATURED",
}

export const loadProfiles = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const query = firebaseHonorUApp
		.firestore()
		.collection("personnel")
		.where("status", "==", "active")
		.limit(3)
		.orderBy("dateUpdated", "desc");
	const querySnapshot = await query.get();
	const profiles = [];
	querySnapshot.forEach((doc) => {
		const data: any = doc.data();
		profiles.push({
			id: doc.id,
			...data,
			from: convertDateFromFirebase(data.from),
			to: convertDateFromFirebase(data.to),
		});
	});
	dispatch({
		type: HonorUActionTypes.LOAD_PROFILES,
		payload: profiles,
	});
	return profiles;
};

export const loadFeaturedProfileAndLandingPageData = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	try {
		const query = firebaseHonorUApp.firestore().collection("page-data").doc("xHCeBghTChe8CLeg6N9R");
		const querySnapshot = await query.get();
		const landingData: any = querySnapshot.data();
		const landingHeadline: string = landingData["landing-headline"];
		dispatch({
			type: HonorUActionTypes.LOAD_HEADLINE,
			payload: landingHeadline,
		});
		const imgFilename: string = landingData["landing-image"];
		const landingMediaHeight: number = landingData["landing-img-height"];
		let landingMedia: IImage;
		if (landingData["landing-display-video"]) {
			const vidfilename: string = landingData["landing-video"];
			let filename: string = vidfilename.replace("mp4", "png");
			const fullUrl: string = storageConfig.getFilePath2("Site Images", "landing", "video", vidfilename); // StorageConfig.constructUrl(StorageConfig.siteImageUrl, "landing", "video", vidfilename);
			const thumbUrl: string = storageConfig.getFilePath2(
				"Site Images",
				"landing",
				"video",
				getThumbFilename(350, filename)
			);
			landingMedia = VideoMedia.createVideo(getThumbFilename(350, filename), fullUrl, "", vidfilename);
			landingMedia.height = landingMediaHeight;
			landingMedia.thumbUrl = thumbUrl;
			dispatch({
				type: HonorUActionTypes.LOAD_FEATURED,
				payload: {
					landingProfile: null,
					landingMedia,
				},
			});
		} else {
			const profileLink: string = querySnapshot.data()["landing-img-link"];
			const id: string = profileLink.substr(profileLink.lastIndexOf("/") + 1);
			const profileQuery = firebaseHonorUApp.firestore().collection("personnel").doc(id);
			const profileQuerySnapshot = await profileQuery.get();
			const data: IHonorUProfile | any = profileQuerySnapshot.data() as IHonorUProfile;
			landingMedia = {
				title: data.name,
				url: storageConfig.getFilePath("Site Images", "landing", imgFilename),
				filename: imgFilename,
				order: 0,
				fileType: FileType.IMAGE,
				height: landingMediaHeight,
				desc: data.summary,
			};
			dispatch({
				type: HonorUActionTypes.LOAD_FEATURED,
				payload: {
					landingProfile: {
						id: profileQuerySnapshot.id,
						...data,
						from: convertDateFromFirebase(data.from),
						to: convertDateFromFirebase(data.to),
					},
					landingMedia,
				},
			});
		}
	} catch {}
};

export const mapHonorUDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadProfiles: () => dispatch(loadProfiles()),
	loadFeaturedProfileAndLandingPageData: () => dispatch(loadFeaturedProfileAndLandingPageData()),
});

export type IHonorUActionDispatches = ReturnType<typeof mapHonorUDispatchToProps>;
