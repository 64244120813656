import React, { CSSProperties, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import { UIStyleProps } from "../../components/ui/UIComponents";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		formControl: {
			margin: theme.spacing(0, 0, 2),
			minWidth: 120,
		},
		selectEmpty: {
			marginTop: theme.spacing(2),
		},
	})
);

export function UISelect() {
	const classes = useStyles({});
	const [values, setValues] = React.useState({
		age: "",
		name: "hai",
	});

	// const inputLabel = React.useRef<HTMLLabelElement>(null);
	// const [labelWidth, setLabelWidth] = React.useState(0);
	// React.useEffect(() => {
	// 	setLabelWidth(inputLabel.current!.offsetWidth);
	// }, []);

	function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
		setValues((oldValues) => ({
			...oldValues,
			[event.target.name as string]: event.target.value,
		}));
	}

	return (
		<form className={classes.root} autoComplete="off">
			<FormControl className={classes.formControl}>
				<InputLabel htmlFor="age-simple">Age</InputLabel>
				<Select
					value={values.age}
					onChange={handleChange}
					inputProps={{
						name: "age",
						id: "age-simple",
					}}
				>
					<MenuItem value={10}>Ten</MenuItem>
					<MenuItem value={20}>Twenty</MenuItem>
					<MenuItem value={30}>Thirty</MenuItem>
				</Select>
			</FormControl>
		</form>
	);
}

const useStylesCheckbox = makeStyles({
	root: {
		// "&:hover": {
		// 	backgroundColor: "transparent"
		// }
	},
	label: {
		fontSize: "11px !important",
	},
	icon: {
		borderRadius: 2,
		width: 16,
		height: 16,
		boxShadow: "inset 0 0 0 1px var(--checkbox-border-color), inset 0 -1px 0 var(--checkbox-border-color)",
		// backgroundColor: "#f5f8fa",
		// backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		// "$root.Mui-focusVisible &": {
		// 	outline: "2px auto rgba(19,124,189,.6)",
		// 	outlineOffset: 2
		// },
		"input:hover ~ &": {
			// backgroundColor: "#ebf1f5",
			boxShadow:
				"inset 0 0 0 1px var(--checkbox-border-color-hover), inset 0 -1px 0 var(--checkbox-border-color-hover)",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		backgroundColor: "#1dafec",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: "#1dafec",
		},
	},
});

export function UICheckbox(
	props: CheckboxProps & {
		label: string;
		labelClassName?: string;
		spanClassName?: string;
		checkboxClassName?: string;
		labelStyle?: CSSProperties;
	}
) {
	const classes = useStylesCheckbox({});
	const { spanClassName, checkboxClassName, labelClassName, ...rest } = props;

	const checkbox = (
		<Checkbox
			color="primary"
			size="small"
			className={clsx(classes.root, checkboxClassName)}
			style={{ marginTop: "2px" }}
			// disableRipple
			// checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			// icon={<span className={classes.icon} />}
			inputProps={{
				"aria-label": props.label,
			}}
			{...rest}
		/>
	);
	if (!props.label) return checkbox;

	return (
		<FormControlLabel
			style={props.labelStyle}
			className={labelClassName}
			control={checkbox}
			label={<span className={clsx("text-sm text-gray", props.spanClassName)}>{props.label}</span>}
		/>
	);
}

type IOption = {
	label: any;
	value: any;
};

type UIComboBoxProps = UIStyleProps &
	Partial<AutocompleteProps<any>> & {
		label?: string | React.ReactNode;
		selectedOption?: IOption;
		options: IOption[];
		onSelect: (selected: IOption) => any;
		inputProps?: TextFieldProps;
	};

export const UIComboBox: React.FC<UIComboBoxProps> = (props: UIComboBoxProps) => {
	const [value, setValue] = useState<any>(props.selectedOption);
	return (
		<Autocomplete
			key={props.selectedOption ? props.selectedOption.value : ""}
			className={clsx("ui-combobox", props.className)}
			// style={props.style}
			options={props.options}
			getOptionLabel={(option) => (option && typeof option === "object" ? option.label : option)}
			// closeIcon={false}
			selectOnFocus={true}
			freeSolo={props.freeSolo}
			autoSelect={props.freeSolo}
			disabled={props.disabled}
			spellCheck={false}
			openText={props.openText}
			openOnFocus={true}
			// InputLabelProps={{
			// 	shrink: true
			// }}
			value={props.selectedOption}
			// inputValue={props.selectedOption ? props.selectedOption.label : undefined}
			// inputValue={typeof value === "object" ? value.value : value}
			renderInput={(params) => {
				return (
					<>
						{props.label}
						<TextField
							{...params}
							// label={props.label}
							autoFocus={false}
							{...props.inputProps}
							variant="outlined"
						/>
					</>
				);
			}}
			blurOnSelect="touch"
			onChange={(e, val) => {
				if (typeof val !== "object") {
					setValue(val);
				}
				props.onSelect(val);
			}}
			onBlur={props.onBlur}
			// size="small"
		/>
	);
};
