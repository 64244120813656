import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete";
import { FormField, FormValidation } from "..";
import { IFormField, FormFieldType, ISelectFormField } from "../../models";
import { IUploadContext } from "../../models/upload";
import { IAuthContext } from "../../auth";

// "text" | "number" | "password" | "boolean" | "date" | "select" | "address";
// | "text"
// 	| "textarea"
// 	| "number"
// 	| "phone" // TODO: with mask
// 	| "email"
// 	| "date"
// 	| "address"
// 	| "select"
// 	| "yes/no"
// 	| "boolean"
// 	| "collection"
// 	| "checkbox"
// 	| "color"
// 	| "link"
// 	| "internalLink"
// 	| "imageUrl"
// 	| "html"
// 	| "widget";

export type SelectOption = { label: string; value: any; desc?: string | any };

export type BaseDynamicFormField = {
	fieldType?: FormFieldType;
	isPercentage?: boolean;
	isPhoneNo?: boolean;
	isInteger?: boolean;
	placeholder?: string;
	infoText?: string | any;
	multiline?: boolean;
	label?: string;
	order?: number;
	disabled?: boolean;
	disabledFn?: (form: FormValidation) => boolean;
	selectOptions?: SelectOption[];
	getSelectOptions?: (authContext?: IAuthContext, form?: DynamicFormValidation) => Promise<SelectOption[]>;
	autoSelectSingleValue?: boolean;
	autoComplete?: string;
	omitAsterik?: boolean;
	postLabelContent?: string | any;
	renderWhen?: (field: DynamicFormField, form: DynamicFormValidation) => boolean;
	optionalWhen?: (field: FormField, form: FormValidation) => boolean;
	autoFocus?: boolean;
	datasource?: any[];
	nameField?: string;
	valueField?: string;
	freeSolo?: boolean;
	getOptionDisplay?: (data) => string | any; // React.ReactNode
	getOptionSelected?: (data, value) => boolean;
	mediaOptions?: Partial<IUploadContext>;
	displayContentLinkified?: boolean;
	autoCompleteProps?: Partial<AutocompleteProps<any>>;
	fireOnChange?: boolean;
};

export type DynamicFormField = FormField &
	BaseDynamicFormField & {
		order: number;
		hide?: boolean;
	};

export class DynamicFormValidation extends FormValidation {
	constructor(fields: { [index: string]: DynamicFormField }) {
		super(fields);
	}

	get isValid(): boolean {
		for (let key in this.fields) {
			const field: DynamicFormField = this.fields[key] as DynamicFormField;
			if (
				!field.valid &&
				!(field.renderWhen && !field.renderWhen(field, this)) &&
				(!field.optionalWhen || !field.optionalWhen(this.fields[key], this))
			)
				return false;
		}
		return true;
	}

	get values(): any {
		let formValues = {};
		for (let key in this.fields) {
			const field: DynamicFormField = this.fields[key] as DynamicFormField;
			if (!field.renderWhen || field.renderWhen(field, this)) {
				const value = this.fields[key].value;
				if (this.fields[key].numeric || this.fields[key].fieldType === "number") {
					if (value !== undefined && value !== null && typeof value !== "string") {
						formValues[key] = Number(value);
					} else {
						formValues[key] = undefined;
					}
				} else {
					formValues[key] = value;
				}
			}
		}
		return formValues;
	}

	public getOrderedFields(): DynamicFormField[] {
		const arr: DynamicFormField[] = new Array(Object.keys(this.fields).length);
		Object.values(this.fields).forEach((field: DynamicFormField) => {
			if (!field.renderWhen || field.renderWhen(field, this)) {
				if (arr[field.order])
					throw Error(`Overriding field ${arr[field.order].key} ordered in position ${field.order}`);
				arr[field.order] = field;
			}
		});
		return arr;
	}
}

export const getInitialState = (form: FormValidation) => {
	return form;
	// return { notes: "123" };
	// state can be simple value as update is triggered
	// however if the object is updated in another manner there won't be a rerender.
	// similarly we would need to call the dispatch to update and reflect the form changes
};

export const getReducer =
	(form: FormValidation) =>
	(state, action): FormValidation => {
		if (action.updateForm) return { ...action.payload };
		form.formFieldOnBlur(action.type, action.payload);
		// return { firstName: action.payload };
		// return { [action.type]: action.payload } as any;
		return { ...form } as FormValidation;
	};
export function getValidatedForm(form: DynamicFormValidation): DynamicFormValidation {
	form.errors = {};
	for (let key in form.fields) {
		// let field: FormField = form.fields[key];
		form.fields[key] = form.validateField(form.fields[key], false);
	}
	console.log("validated form:", form);
	return form;
}
