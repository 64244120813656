import React, { Component } from "react";
import { ReplaySubject } from "rxjs";
import { IForm } from "../../laco-common";
import { IFormSubmission } from "../../laco-common/base/models/formSubmission";
import { OrderItem } from "../../laco-common/base/models/order";
import { LOCAL_STORAGE_KEYS } from "../../models/localStorage";
import { addToCart, setEmail } from "../../redux/actions/stripe";
// import { EntityType, getEntityUrl, getEntityUrlByParams, IEntity } from 'common/lib/models/Entity';
// import { PlanType } from 'common/lib/models/Plan';
import { waitForElement, waitForObject } from "../../utils";

declare var xProductBrowser: any;
declare var Ecwid: any;
declare var EcwidApp: any;
declare var ecwid_onBodyDone: any;
declare var ec: any;
const STORE_ID: string = process.env.REACT_APP_ECWID_STORE_ID;

export const isPaymentStatusComplete = (order: IOrder): boolean => {
	return order.paymentStatus === "PAID" || order.paymentStatus === "ACCEPTED";
};

export enum APP_STORE_EVENTS {
	STORE_LOADED = "STORE_LOADED",
}

export interface ProductOptions {
	UserUid: string;
	Email: string;
	// PageType: EntityType;
	// PageId: string; // TODO
	// PageURL?: string;
	// EditPageURL?: string;
	TransactionTime?: string;
}

export interface IProduct {
	id: number;
	sku: string;
	name: string;
	price: number;
	shortDescription: string;
	url: string;
}

export interface ICart {
	cartId: string;
	items: ICartItem[];
	orderId: number;
	productsQuantity: number;
	weight: number;
	shippingPerson: any; // {countryCode: "US", stateOrProvinceCode: "CO", countryName: "United States"}
}

export interface ICartItem {
	id: number; // product no
	quantity: number;
	options?: any;
	callback?: (success: boolean, product: IProduct, cart: ICart) => any;
}

export enum PaymentStatus {
	ACCEPTED = "ACCEPTED",
	AWAITING_PAYMENT = "AWAITING_PAYMENT",
	PAID = "PAID",
	CANCELLED = "CANCELLED",
	REFUNDED = "REFUNDED",
	PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
}

export interface IOrder {
	vendorNumber: string; // order string id
	paymentStatus: PaymentStatus; // ACCEPTED
	date: Date;
	orderNumber: number;
	items: { product: IProduct; quantity: number }[];
	customer: { name: string; email: string };
}

export interface CustomerAddress {
	name: string;
	companyName?: string;
	street?: string;
	city?: string;
	countryName?: string;
	postalCode?: string;
	stateOrProvinceCode?: string;
	phone?: string;
}

export const SUBSCRIPTION_PRODUCT_ID: number = 209349614;

export function getProductId(): number {
	return SUBSCRIPTION_PRODUCT_ID; // bw subscription
	// return 137688236; //"01001"; // honoru basic plan
}

// export function getProductIdByPlan(planType: PlanType): number {
//     console.debug("getProductIdByEntity")
//     switch (planType) {
//         case PlanType.BASIC:
//             return 137688236; //"01001";
//             break;
//         case PlanType.PREMIUM:
//             return 137688481; //"01002";
//             break;
//     }

//     return undefined;
// }

export function createCartItem(options: ProductOptions): ICartItem {
	const productId: number = getProductId();
	const product: ICartItem = {
		id: productId,
		quantity: 1,
		options: {
			...options,
			TransactionTime: new Date().toLocaleString(),
			// PageURL: window.location.origin + getEntityUrlByParams(options.PageId, options.PageType), // TODO
			// EditPageURL: window.location.origin + window.location.pathname,
		},
	};
	console.debug(product);
	return product;
}

function createPlanProductRequest(
	productId: number,
	options: ProductOptions,
	callback: (success: boolean, product: IProduct, cart) => any
) {
	const product: ICartItem = {
		id: productId,
		quantity: 1,
		options: {
			...options,
			TransactionTime: new Date().toLocaleString(),
			// PageURL: window.location.origin + getEntityUrlByParams(options.PageId, options.PageType), // TODO
			// EditPageURL: window.location.origin + window.location.pathname,
		},
		callback: callback,
	};

	console.debug(product);
	return product;
}

// init store with UI, product view
function _initStore(containerId?: string, storeLoadedCallback?: any) {
	// WORKS:
	window["ecwid_script_defer"] = true;
	window["ecwid_dynamic_widgets"] = true;

	// if (typeof Ecwid != 'undefined' && Ecwid.destroy) {
	//     Ecwid.destroy();
	// }
	window["_xnext_initialization_scripts"] = [
		{
			widgetType: "ProductBrowser",
			id: containerId || `my-store-${STORE_ID}`,
			// defaultCategoryId: 0,
			arg: ["id=productBrowser"],
		},
	];

	window.localStorage.setItem("show_ec_logs", "true");

	if (!document.getElementById("ecwid-script")) {
		var script: any = document.createElement("script");
		script.charset = "utf-8";
		script.type = "text/javascript";
		script.src = `https://app.ecwid.com/script.js?${STORE_ID}`;
		script.id = "ecwid-script";

		if (storeLoadedCallback) {
			if (script.readyState) {
				// IE
				script.onreadystatechange = () => {
					if (script.readyState === "loaded" || script.readyState === "complete") {
						script.onreadystatechange = null;
						storeLoadedCallback();
					}
				};
			} else {
				script.onload = () => {
					storeLoadedCallback();
				};
			}
		}

		document.body.appendChild(script);
	} else {
		waitForObject("Ecwid", () => {
			console.log("Ecwid loaded in window");
			if (storeLoadedCallback) {
				storeLoadedCallback();
			}
			if (ecwid_onBodyDone) ecwid_onBodyDone();
		});
	}
}

// export const StoreContext: any = React.createContext({
// 	plans: {
// 		basic: {
// 			imagesMaxCount: 6,
// 		},
// 	},
// });

class AppStore {
	public events = new ReplaySubject<APP_STORE_EVENTS>(1);
	public reduxStore; // redux store reference
	private _isStoreLoaded: boolean = false;
	private history;

	constructor() {
		// Initialize the application through JS SDK
		// https://developers.ecwid.com/api-documentation/ecwid-javascript-sdk
		// just allows delayed loading - which is what I've implemented with the load script
		// EcwidApp.init({
		//     app_id: STORE_ID,
		//     autoloadedflag: false,
		//     autoheight: true // EcwidApp.setSize()
		//     // paymentMethod
		// });
		// initStore(() => {
		//     this.events.next(APP_STORE_EVENTS.STORE_LOADED);
		//     Ecwid.OnAPILoaded.add(function () {
		//         console.debug("Ecwid storefront JS API has loaded");
		//     });
		// });
		// other methods:
		// EcwidApp.setAppStorage
		// EcwidApp.setAppPublicConfig
		// EcwidApp.getAppStorage
		// EcwidApp.getAppPublicConfig
		// EcwidApp.closeAppPopup();
		// EcwidApp.ready();
		// const storeData = EcwidApp.getPayload();
		// console.debug(storeData);
		// const storeId = storeData.store_id;
		// const accessToken = storeData.access_token;// Secure oAuth token - for REST API
		// var language = storeData.lang;
		// var viewMode = storeData.view_mode;
		// if (storeData.public_token !== undefined) {
		//     var publicToken = storeData.public_token; // Public oAuth token for Ecwid REST API
		// }
		// if (storeData.app_state !== undefined) {
		//     var appState = storeData.app_state; // URL Encoded application state
		// }
	}

	public setReactRouter(history) {
		this.history = history;
	}

	public initStore(containerId: string, onload?: () => any, onOrderComplete?: (order: IOrder) => any) {
		if (typeof Ecwid != "undefined" && Ecwid.destroy) Ecwid.destroy();
		(window as any).ec = (window as any).ec || Object();
		(window as any).ec.config = (window as any).ec.config || Object();
		(window as any).ec.config.navigation_scrolling = "DISABLED";
		// setTimeout(() => {
		// EcwidApp.init({ // not needed because I implemented that with loadScript
		//     app_id: STORE_ID,
		//     autoloadedflag: true,
		//     autoheight: true // EcwidApp.setSize()
		// });
		_initStore(containerId, () => {
			console.log("Ecwid loaded in window");
			Ecwid.OnAPILoaded.add(() => {
				console.debug("Ecwid storefront JS API has loaded");
				this._isStoreLoaded = true;
				(window as any).ec = (window as any).ec || Object();
				(window as any).ec.config = (window as any).ec.config || Object();
				(window as any).ec.config.navigation_scrolling = "DISABLED";

				// this.events.next(APP_STORE_EVENTS.STORE_LOADED);
				if (onload) {
					setTimeout(() => {
						onload();
					}, 200);

					Ecwid.OnOrderPlaced.add((order) => {
						console.debug("Ecwid.OnOrderPlaced:", order);
						console.debug(order.orderNumber);
						console.debug(order);
						if (onOrderComplete) {
							onOrderComplete(order);
						}
					});
				}

				Ecwid.OnOrderPlaced.add((order) => {
					console.debug("Ecwid.OnOrderPlaced:", order);
					console.debug(order.orderNumber);
					console.debug(order);
					if (onOrderComplete) {
						onOrderComplete(order);
					}
				});
			});

			Ecwid.OnCartChanged.add((cart) => {
				console.debug("Ecwid OnCartChanged:", cart);
			});

			Ecwid.OnOrderPlaced.add((order) => {
				console.debug("Ecwid.OnOrderPlaced:", order);
				console.debug(order.orderNumber);
				console.debug(order);
				if (onOrderComplete) {
					onOrderComplete(order);
				}
			});
		});
		this.enableLogs();
	}

	public enableLogs() {
		localStorage.setItem("show_ecwid_logs", "true");
	}

	public disableLogs() {
		localStorage.setItem("show_ecwid_logs", null);
	}

	public goToEcwidPayment = (productId: number, formId: string, email: string, uid?: string) => {
		// ecwid implementation:
		this.setCustomerEmail(email);
		localStorage.setItem(LOCAL_STORAGE_KEYS.STORE_FORM_ID, formId);
		this.addToCartWithOptions(productId, { UserUid: "", Email: email }, (success: boolean, product, cart) => {
			if (success) {
				this.setCustomerEmail(email);
				window.location.href = `${window.location.origin}/payment-checkout#!/~/cart`;
				// this.history.push(`/payment-checkout#!/~/cart`);
				setTimeout(() => {
					this.setCustomerEmail(email);
				}, 200);
			}
			// store.goToCheckout();
		});
	};

	public goToPayment = (formSubmission: IFormSubmission, form: IForm) => {
		const { id, name, description, productPrice, priceMultiplierField } = form;
		const amount: number = formSubmission[priceMultiplierField] || 1;
		const item: OrderItem = {
			id,
			name,
			description,
			price: productPrice,
			amount,
			isForm: true,
			formSubmissionId: formSubmission.id,
		};
		this.reduxStore.dispatch(
			setEmail(formSubmission.formSubmissionUserDetails?.email || formSubmission["user-email"])
		);
		this.reduxStore.dispatch(addToCart(item));
		this.history.push(`/payment`);
	};

	public destroy() {
		if (typeof Ecwid != "undefined" && Ecwid.destroy) Ecwid.destroy();
	}

	// private executeAfterLoad(callback: any, ...args) {
	//     console.debug("executeAfterLoad");
	//     console.debug("this._isStoreLoaded:", this._isStoreLoaded);
	//     if (this._isStoreLoaded) {
	//         callback(args);
	//         return;
	//     }
	//     // const sub = this.events.subscribe((evt: APP_STORE_EVENTS) => {
	//     //     // if (!this._authStateRetrieved) { // sometimes firebase subscription is missed
	//     //     switch (evt) {
	//     //         case APP_STORE_EVENTS.STORE_LOADED:
	//     //             console.error("STORE LOADED. calling callback");
	//     //             callback(args);
	//     //             break;
	//     //     }
	//     // });

	//     // sub.unsubscribe();
	// }

	// public displayStore() {
	//     // Show app UI
	//     //EcwidApp.ready();
	//     // EcwidApp.setSize({height: 800}); // iframe height
	// }

	public onOrderComplete(callback: any) {
		// callback: (order: IOrder) => any
		console.debug("AppStore.onOrderComplete");
		console.debug(callback);
		Ecwid.OnOrderPlaced.add((order) => {
			console.debug("Ecwid.OnOrderPlaced", order);
			console.debug(order.orderNumber);
			console.debug(order);
			callback(order);
		});

		// Ecwid.OnOrderPlaced.registerConsumer(function (order) {
		//     console.debug("Ecwid.OnOrderPlaced", order);
		//     console.debug(order.orderNumber);
		//     console.debug(order);
		//     callback(order);
		// });

		// Ecwid.OnOrderPlaced.add(callback);
	}

	public openPage(params: string): void {
		Ecwid.openPage(params);
	}

	// https://developers.ecwid.com/api-documentation/manage-customer-cart
	public addToCart(productID: number, callback: (success: boolean, product: IProduct, cart: ICart) => any) {
		Ecwid.Cart.addProduct(productID, callback);
	}

	public addToCartWithOptions(
		productID: number,
		options: ProductOptions,
		callback: (success: boolean, product: IProduct, cart: ICart) => any,
		onOrderComplete?: any
	) {
		console.debug("addToCartWithOptions");
		// this.executeAfterLoad(() => {
		const productRequest = createPlanProductRequest(productID, options, callback);
		console.debug("productRequest:", productRequest);

		// Ecwid.Cart.get(function (cart) {
		//     // alert(cart.productsQuantity + " products in cart now");
		//     Ecwid.Cart.clear();
		//     Ecwid.Cart.addProduct(productRequest);
		// });

		Ecwid.OnOrderPlaced.add((order) => {
			console.debug("Ecwid.OnOrderPlaced:", order);
			console.debug(order.orderNumber);
			console.debug(order);
			if (onOrderComplete) {
				onOrderComplete(order);
			}
		});

		Ecwid.Cart.clear();
		setTimeout(() => {
			// without this the cart isn't cleared and items are duplicated
			Ecwid.Cart.clear();
			Ecwid.Cart.addProduct(productRequest);

			Ecwid.OnOrderPlaced.add((order) => {
				console.debug("Ecwid.OnOrderPlaced:", order);
				console.debug(order.orderNumber);
				console.debug(order);
				if (onOrderComplete) {
					onOrderComplete(order);
				}
			});
		}, 500);

		setTimeout(() => {
			Ecwid.OnOrderPlaced.add((order) => {
				console.debug("Ecwid.OnOrderPlaced:", order);
				console.debug(order.orderNumber);
				console.debug(order);
				if (onOrderComplete) {
					onOrderComplete(order);
				}
			});
		}, 2000);

		// });
		//   var product = {
		//     id: 10,
		//     quantity: 3,
		//     options: {
		//       someTextOption: "Your name",
		//       someDateOption: new Date().getTime().toString(),
		//       someRadioOption: "Use default color",
		//       someDropDownOption: "I want custom engraving",
		//       someCheckboxOption: ["It's a gift", "Gold engraving"]
		//     },
		//     callback: function(success, product, cart) {
		//       // ...
		//     }
		//   }
	}

	public generateCartLink(cartItems: ICartItem[], email: string): string {
		//cartItems:ICartItem[]
		const cart = {
			gotoCheckout: true, // go to next checkout step right away (after 'Cart' page)
			products: cartItems, // products to add to cart
			profile: {
				// "address": { // Shipping address details
				//     "name": "john smith",
				//     "companyName": "general motors",
				//     "street": "5th Ave",
				//     "city": "New York",
				//     "countryCode": "US",
				//     "postalCode": "10002",
				//     "stateOrProvinceCode": "NY",
				//     "phone": "+1 234 235 22 12"
				// },
				// "billingAddress": { // Billing address details
				//     "countryCode": "US",
				//     "stateOrProvinceCode": "AL",
				// },
				email: email, // Customer email
				// "orderComments": "Comments!" // Order comments
			},
			email: email,
		};

		console.debug(cart);

		const cartLink: string = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(cart))));
		console.debug(cartLink);

		return cartLink;
	}

	public getCartEndodedURL(cartLink: string) {
		// return window.location.origin + `/store#!/~/cart/create=${cartLink}`; // works - super similar to generating cart
		return window.location.origin + `/store#!/~/cart/create=${cartLink}`;
	}

	public getPaymentPageUrl(cart: ICart): string {
		return `https://store${STORE_ID}.ecwid.com/?checkout&orderId=${cart.orderId}&cartId=${cart.cartId}#!/~/checkoutPD`;
	}

	public removeFromCart(index?: number) {
		// this.executeAfterLoad(() => {
		Ecwid.Cart.removeProduct(index || 0);
		// });
	}

	public clearCart() {
		// this.executeAfterLoad(() => {
		console.debug("CLEAR CART");
		console.debug(Ecwid.Cart);
		Ecwid.Cart.clear();
		// });
	}

	public goToCheckout(goToPayment?: boolean, callback?: () => any) {
		Ecwid.Cart.canGotoCheckout(function () {
			// console.log(callback);
			Ecwid.Cart.gotoCheckout();
			// Ecwid.openPage('checkout/payment');
			// this.openPage("checkout/payment"); // TODO: wait for Ecwid's answer. Can wait and click button for user
			// this.openPage("!/~/checkoutPD");

			if (goToPayment) {
				waitForElement(
					".ec-cart__checkout .ec-cart__buttons .ec-cart__button--checkout button",
					(button: HTMLButtonElement) => {
						button.click();
						callback();
					}
				);
			} else {
				callback();
			}
		});

		// this.openPage("checkout/payment");

		// setTimeout(() => {
		//     // this.openPage("!/~/checkoutPD");
		//     this.openPage("checkout/payment");
		// }, 2000);

		// Ecwid.Cart.canGotoCheckout(function(callback){
		//     console.log(callback);
		//   });

		// this.executeAfterLoad(() => {
		//Ecwid.Cart.gotoCheckout(); // not working. Click button for user

		// });

		// const checkoutBtn: any = document.getElementById(containerId).querySelector('.ec-cart__button--checkout');
		// console.debug(checkoutBtns)
		// if (checkoutBtns) {
		//     const checkoutBtn = checkoutBtns[0];
		// console.debug(checkoutBtn);
		// }

		// if (checkoutBtn) {
		//     //checkoutBtn.click();

		//     setTimeout(() => {
		//         const paymentBtn = document.querySelector('.ec-store__checkout-page');
		//         console.debug(paymentBtn)
		//     }, 200);

		// }

		// setTimeout(() => {
		//     Ecwid.Cart.gotoCheckout();
		// }, 1000);
	}

	public setCustomerEmail(email: string, successCallback?: any, errorCallback?: any) {
		// this.executeAfterLoad(() => {
		Ecwid.Cart.setCustomerEmail(email, successCallback, errorCallback);
		// });
	}

	public setCustomerAddress(address: CustomerAddress, successCallback?: any, errorCallback?: any) {
		// this.executeAfterLoad(() => {
		Ecwid.Cart.setAddress(address, successCallback, errorCallback);
		Ecwid.Cart.setBillingAddress(address, successCallback, errorCallback);
		// });
	}

	// added to email as well
	public setOrderComments(comments: string, successCallback?: any, errorCallback?: any) {
		// this.executeAfterLoad(() => {
		Ecwid.Cart.setOrderComments(comments, successCallback, errorCallback);
		// });
	}

	public setOrderReferer(referer: string) {
		// this.executeAfterLoad(() => {
		ec.order = ec.order || {};
		ec.order.referer_id = referer;
		// });
	}

	public displayCart() {
		EcwidApp.openPage("!/~/cart");
	}

	public goToFinalCheckout() {
		EcwidApp.openPage("!/~/checkoutPD");
		// TODO
	}

	public openOrderDetails(orderNumber: number) {
		EcwidApp.openPage(`order:id=${orderNumber}&return=orders`);
	}

	public getOrderUrl(orderNumber: number): string {
		return `https://my.ecwid.com/store/${STORE_ID}#order:id=${orderNumber}&return=orders`;
	}
}

const Singleton = (function () {
	let _appStore: AppStore;

	return {
		getInstance: (): AppStore => {
			if (!_appStore) {
				_appStore = new AppStore();
			}
			return _appStore;
		},
	};
})();

export default Singleton.getInstance();
