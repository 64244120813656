import { Platform } from "react-native";
import { ICustomValidatorResult, FormField, FormValidation, ErrorType } from "../laco-common/base/form-validation";
import { Address, AddressBase, GeoLocation } from "../laco-common/base/models/address";

export type Place = {
	formatted_address: string;
	place_id: string;
	geometry: { location: { lat: () => number; lng: () => number } };
	address_components: AddressComponent[];
};

export type UnmatchedPlace = {
	name: string;
};

type AddressComponentType =
	| "country"
	| "postal_code"
	| "political"
	| "locality"
	| "street_number"
	| "route"
	| "administrative_area_level_1"
	| "administrative_area_level_2"
	| "administrative_area_level_3";

type AddressComponent = { long_name: string; short_name: string; types: AddressComponentType[] };

export type PlaceType = "address" | "(regions)" | "(cities)" | "geocode" | "establishment"; // https://developers.google.com/places/web-service/autocomplete#place_types

export const getZipCode = (locationResult: Place): string => {
	let zip: string;
	(locationResult.address_components || []).forEach((component: AddressComponent) => {
		if (component.types.find((t: AddressComponentType) => t === "postal_code")) {
			zip = component.long_name;
			return zip;
		}
	});
	return zip;
};

export const getGeoLocation = (place: Place): GeoLocation => {
	if (place && place.geometry) return { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
	return undefined;
};

export const getAddress = (place: Place): Address => {
	if (!place.address_components || !place.geometry) return place as any;
	// place may not have all address_components, only the ones that have been selected
	const address: Partial<Address> = {
		...getGeoLocation(place),
		formatted_address: place.formatted_address,
		place_id: place.place_id,
	};

	function setProp(component: AddressComponent) {
		if (component.types.find((t: AddressComponentType) => t === "country")) {
			address.country = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "administrative_area_level_1")) {
			address.state = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "administrative_area_level_2")) {
			address.county = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "locality")) {
			address.city = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "route")) {
			address.street = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "street_number")) {
			address.street_no = component.short_name;
			return;
		}
		if (component.types.find((t: AddressComponentType) => t === "postal_code")) {
			address.zip_code = component.short_name;
			return;
		}
	}

	place.address_components.forEach((component: AddressComponent) => {
		setProp(component);
	});

	return address as Address;
};

export const addressValidator = (field: FormField, form: FormValidation): ICustomValidatorResult => {
	const place: Place = field?.value as Place;
	if (!place && !field?.mandatory) return { field };
	if (!place?.formatted_address) {
		return {
			field,
			errType: ErrorType.Invalid,
			msg: "Invalid address",
		};
	}
	const zipCode: string = (place as any as AddressBase).zip_code || getZipCode(place);
	if (!zipCode) {
		return {
			field,
			errType: ErrorType.Invalid,
			msg: "Zip code missing",
		};
	}
	return {
		field,
	};
};

export const locationLink = (location: string) => {
	if (!location) return undefined;
	return `http://maps.${
		Platform.OS === "ios" || Platform.OS === "macos" ? "apple" : "google"
	}.com/?daddr=${encodeURIComponent(location)}`;
};
