import { createContext, Context } from "react";
import { renderMobileHyperLink } from "../components/HyperLink";
import { IForm, IFormInputProps } from "../base/models";
import { IFormSubmission } from "../base/models/formSubmission";

export interface IPlatformContext {
	renderHyperLink?: typeof renderMobileHyperLink;
	renderFormInputTextSelect?: (params: IFormInputProps) => any;
	renderFormInputPhone?: (params: IFormInputProps) => any;
	renderFormInputAddress?: (params: IFormInputProps) => any; //React.Component<IFormInputProps>;
	initStore?: (containerId?: string, onload?: () => any, onOrderComplete?: (order) => any) => any;
	goToPayment?: (formSubmission: IFormSubmission, form: IForm) => any;
	goToEcwidPayment?: (productId: number, formId: string, email: string, uid?: string) => any;
}

export const PlatformContext: Context<IPlatformContext> = createContext<IPlatformContext>({});
