import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapAudioStateToProps } from "../reducers/audio";
import { getDocument, getCollection } from "../../laco-common/base/firestore-utils";
import { ISiteAudioContent } from "../../models/cms";
import { ISitePage } from "../../laco-common/base/models/page";

export enum AudioActionTypes {
	LOAD_AUDIO_FILE = "[audio] LOAD_AUDIO_FILE",
	LOAD_AUDIO_FILES = "[audio] LOAD_AUDIO_FILES",
}

export const loadAudioFile = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const page: ISitePage = getState().appStore.activePage;
	if (!page) return;
	// TODO: return cached?
	const audioFilesConfig: ISiteAudioContent = await getDocument<ISiteAudioContent>(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		"site_audio",
		page.id // TODO: nati add active query
	);
	dispatch({
		type: AudioActionTypes.LOAD_AUDIO_FILE,
		payload: { pageId: page.id, audioFilesConfig },
	});
	return audioFilesConfig;
};

export const loadAudioFiles = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const audioContents: ISiteAudioContent[] = await getCollection<ISiteAudioContent>(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		"site_audio"
	);
	const audioMap: { [id: string]: ISiteAudioContent } = {};
	(audioContents || []).forEach((audio: ISiteAudioContent) => {
		audioMap[audio.id] = audio;
	});
	dispatch({
		type: AudioActionTypes.LOAD_AUDIO_FILES,
		payload: { audioFilesConfig: audioMap },
	});
	return audioMap;
};

export const mapAudioDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadAudioFile: () => dispatch(loadAudioFile()),
	loadAudioFiles: () => dispatch(loadAudioFiles()),
});

export type IAudioActionDispatches = ReturnType<typeof mapAudioDispatchToProps>;
export type IAudioStoreProps = ReturnType<typeof mapAudioStateToProps> & ReturnType<typeof mapAudioDispatchToProps>;
