import { LOCAL_STORAGE_KEYS } from "../models/localStorage";
import { isLocalhost } from "../serviceWorker";

// SET DEBUG MODE
export const DEBUG_MODE: boolean = (function isDebugMode(): boolean {
	const debugMode = localStorage.getItem(LOCAL_STORAGE_KEYS.DEBUG_MODE);
	return (debugMode && debugMode === "true") || isLocalhost;
})();

export const MOCK_APIS: boolean = (function mockApis(): boolean {
	const doMock = localStorage.getItem(LOCAL_STORAGE_KEYS.MOCK_APIS);
	return doMock && doMock === "true";
})();

export const OFFLINE_MODE: boolean = (function offlineMode(): boolean {
	const offline = localStorage.getItem(LOCAL_STORAGE_KEYS.OFFLINE_MODE);
	return offline && offline === "true";
})();

export const IS_AUTH_REMOVED: boolean = OFFLINE_MODE && DEBUG_MODE && isLocalhost;
