import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DynamicFormFieldProps } from "../DynamicForm";
import { DynamicFormField } from "../../laco-common/base/form-validation/dynamic";
import { FormLabel } from ".";

// export const DateInput: React.FC<DynamicFormFieldProps> = (props: DynamicFormFieldProps) => {
// 	const field: DynamicFormField = props.field;
// 	const { index, onChange, onBlur, processing, getHelperText, omitLabel } = props;
// 	return (
// 		<MuiPickersUtilsProvider utils={DateFnsUtils}>
// 			<KeyboardDatePicker
// 				disableToolbar
// 				variant="inline"
// 				format="yyyy-MM-dd"
// 				label={omitLabel ? undefined : <FormLabel field={field} />}
// 				className={"w-100 " + (props.className || "")}
// 				// maxDate={new Date()}
// 				value={field.value}
// 				autoOk={true}
// 				autoFocus={index === 0}
// 				KeyboardButtonProps={{
// 					"aria-label": "Change date",
// 				}}
// 				onChange={onChange}
// 				onBlur={onBlur}
// 				error={field.touched && !field.valid}
// 				helperText={getHelperText ? getHelperText(field) : undefined}
// 				disabled={field.disabled || processing}
// 			/>
// 		</MuiPickersUtilsProvider>
// 	);
// };

function pad(number) {
	if (number < 10) {
		return "0" + number;
	}
	return number;
}

const toDateTimeString = function (date: Date) {
	return (
		date.getFullYear() +
		"-" +
		pad(date.getMonth() + 1) +
		"-" +
		pad(date.getDate()) +
		"T" +
		pad(date.getHours()) +
		":" +
		pad(date.getMinutes()) +
		":" +
		pad(date.getSeconds()) +
		"." +
		(date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
		"Z"
	);
};

export const DateInput: React.FC<DynamicFormFieldProps> = (props: DynamicFormFieldProps) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [defaultValue, setDefaultValue] = useState<string>();
	const field: DynamicFormField = props.field;
	const { index, onChange, onBlur, processing, getHelperText, omitLabel } = props;

	useEffect(() => {
		if (field.fieldType === "datetime") {
			let dateStr: string;
			try {
				if (field.value) {
					const d = new Date(field.value);
					dateStr = toDateTimeString(d); //d.toISOString();
					setDefaultValue(dateStr.substr(0, 16));
					// setDefaultValue("2017-05-24T10:30");
				}
			} catch (err) {
				console.error("Failed to parse date:", err);
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 1);
	}, []);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			{!loading && field.fieldType === "datetime" && (
				<TextField
					label={field.label}
					type="datetime-local"
					defaultValue={defaultValue}
					// className={classes.textField}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => {
						const d = new Date(e.target.value);
						onChange(d.getTime());
					}}
					// onBlur={onBlur}
					error={field.touched && !field.valid}
					helperText={getHelperText ? getHelperText(field) : undefined}
					disabled={field.disabled || processing}
				/>
			)}
			{field.fieldType === "date" && (
				<>
					{!omitLabel && <FormLabel field={field} getForm={props.getForm} />}
					<KeyboardDatePicker
						// disableToolbar
						variant="inline"
						inputVariant="outlined"
						format="yyyy-MM-dd"
						margin="normal"
						// views={["date", "year", "month"]}
						label={
							omitLabel ? undefined : (
								<>
									{/* {field.label} */}
									{!!field.infoText && (
										<i>
											<i className="im im-info ml-3 mr-1"></i>
											{field.infoText}
										</i>
									)}
								</>
							)
						}
						className={"w-100 " + (props.className || "")}
						// maxDate={new Date()}
						value={field.value}
						autoOk={true}
						KeyboardButtonProps={{
							"aria-label": "Change date",
						}}
						onChange={onChange}
						onBlur={onBlur}
						error={field.touched && !field.valid}
						helperText={getHelperText ? getHelperText(field) : undefined}
						disabled={field.disabled || processing}
					/>
				</>
			)}
		</MuiPickersUtilsProvider>
	);
};

export default DateInput;
