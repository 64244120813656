import { createContext, Context } from "react";
import { ITenant } from "../../laco-common/base/models/tenant";
import { IFirebaseContext } from "../../laco-common/base/firebase";
// import { IAuthContext } from "../auth/AuthContext.ts__";
// import { IAPIContext } from "../api/APIContext";

export interface IAppConfig {
	appVersion: string;
	tenant: ITenant;
	siteTenant: ITenant;
	iOSlink?: string;
	androidLink?: string;
}

// general static app configuration here
export const APP_CONFIG: IAppConfig = {
	appVersion: process.env.REACT_APP_VERSION,
	tenant: process.env.REACT_APP_TENANT as ITenant,
	siteTenant: process.env.REACT_APP_SITE_TENANT as ITenant,
	iOSlink: "https://apps.apple.com/us/app/firewire-app/id1472539280",
	androidLink: "https://play.google.com/store/apps/details?id=com.firewireapp",
};

console.log("APP_CONFIG:", APP_CONFIG);

// expose contexts through 1 appContext
export interface IAppContext {
	config: IAppConfig;
	firebase: IFirebaseContext;
}

const AppContext: Context<IAppContext> = createContext<IAppContext>({
	config: APP_CONFIG,
	firebase: null,
});

export default AppContext;
