import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapFooterStateToProps, IFooterState } from "../reducers/footer";
import { getDocument } from "../../laco-common/base/firestore-utils";

export enum FooterActionTypes {
	LOAD_FOOTER = "[footer] LOAD_FOOTER",
}

export const loadFooter = (): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const footer: IFooterState = await getDocument<IFooterState>(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		"site_footer",
		"footer"
	);
	const payload: IFooterState = {
		...footer,
	};
	dispatch({
		type: FooterActionTypes.LOAD_FOOTER,
		payload,
	});
	return payload;
};

export const mapFooterDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadFooter: () => dispatch(loadFooter()),
});

export type IFooterActionDispatches = ReturnType<typeof mapFooterDispatchToProps>;
export type IFooterStoreProps = ReturnType<typeof mapFooterStateToProps> & ReturnType<typeof mapFooterDispatchToProps>;
