import { ITenant } from "../base/models/tenant";

// export const clientFirebasesConfig = {
//     "la-county-fire-dep": {
//         apiKey: "AIzaSyB3iHaQeYHVUJIEv4M2kDLxVt0B6pKGrU4",
//         authDomain: "la-county-fire-dep.firebaseapp.com",
//         databaseURL: "https://la-county-fire-dep.firebaseio.com",
//         projectId: "la-county-fire-dep",
//         storageBucket: "la-county-fire-dep.appspot.com",
//         messagingSenderId: "1007040707255",
//         appId: "1:1007040707255:web:c60c8a5263b74102"
//     },
//     "local-119-u": {
//         apiKey: "AIzaSyD9Vrp-WHdYT_4aXdniPkEDsmG79y_4VnI",
//         authDomain: "local-119-u.firebaseapp.com",
//         databaseURL: "https://local-119-u.firebaseio.com",
//         projectId: "local-119-u",
//         storageBucket: "local-119-u.appspot.com",
//         messagingSenderId: "702419515028",
//         appId: "1:702419515028:web:c277041a31260c23"
//     }
// }

export const rnClientsConfig = {
	"la-county-fire-dep": {
		ios: {
			clientId: "1007040707255-ilmein6hfnig72scgfb66q2ckceblvqt.apps.googleusercontent.com",
			// appId: '1472539280', // app store id
			appId: "1:1007040707255:ios:4bf66e0d00afab31", // google app id
			apiKey: "AIzaSyD3gCLA72vjNPpk5EqJOtyood7tTNiKOWw",
			databaseURL: "https://la-county-fire-dep.firebaseio.com",
			storageBucket: "la-county-fire-dep.appspot.com",
			messagingSenderId: "1007040707255",
			projectId: "la-county-fire-dep",
			// persistence: true,
		},
		android: {
			clientId: "1007040707255-i5cuvq8lk00cl65k2mbi3hv01n1004g2.apps.googleusercontent.com",
			// appId: '1472539280', // app store id
			appId: "1:1007040707255:android:4bf66e0d00afab31", // google app id
			apiKey: "AIzaSyCLXJn9Dlg4bvTuo24YRDS2whO7h6a6XXc",
			databaseURL: "https://la-county-fire-dep.firebaseio.com",
			storageBucket: "la-county-fire-dep.appspot.com",
			messagingSenderId: "1007040707255",
			projectId: "la-county-fire-dep",
		},
		web: {
			apiKey: "AIzaSyB3iHaQeYHVUJIEv4M2kDLxVt0B6pKGrU4",
			authDomain: "la-county-fire-dep.firebaseapp.com",
			databaseURL: "https://la-county-fire-dep.firebaseio.com",
			projectId: "la-county-fire-dep",
			storageBucket: "la-county-fire-dep.appspot.com",
			messagingSenderId: "1007040707255",
			appId: "1:1007040707255:web:da1039753ad55cdb676b32",
		},
		hasPeerSupport: true,
	},
	"local-119-u": {
		ios: {
			clientId: "702419515028-h66fesb62ctsp09jfherig673ss6r2lp.apps.googleusercontent.com",
			appId: "1:702419515028:ios:4bf66e0d00afab31", // google app id
			apiKey: "AIzaSyDrIxMNXOtely1ooSTKJc4yHYx7l9E0FKo",
			databaseURL: "https://local-119-u.firebaseio.com",
			storageBucket: "local-119-u.appspot.com",
			messagingSenderId: "702419515028",
			projectId: "local-119-u",
		},
		android: {
			clientId: "702419515028-h2686ev7v42jq8igflrs7kuqp45v2sbu.apps.googleusercontent.com",
			appId: "1:702419515028:android:4bf66e0d00afab31", // google app id
			apiKey: "AIzaSyBfykKBEOy3Tbcj5Oyl5U3yv8rHyvjmKgA",
			databaseURL: "https://local-119-u.firebaseio.com",
			storageBucket: "local-119-u.appspot.com",
			messagingSenderId: "702419515028",
			projectId: "local-119-u",
		},
		web: {
			apiKey: "AIzaSyD9Vrp-WHdYT_4aXdniPkEDsmG79y_4VnI",
			authDomain: "local-119-u.firebaseapp.com",
			databaseURL: "https://local-119-u.firebaseio.com",
			projectId: "local-119-u",
			storageBucket: "local-119-u.appspot.com",
			messagingSenderId: "702419515028",
			appId: "1:702419515028:web:c277041a31260c23",
		},
	},
	"firewire-test-db": {
		ios: {
			clientId: "189220152595-ns96c27jg5afnkfn082sns6mp7605s04.apps.googleusercontent.com",
			appId: "1:189220152595:ios:59b0c65e1c4614753366e6", // google app id
			apiKey: "AIzaSyDJ7Z0aOR85HCa8WtZ9PVzIEaTrmSJ_bxo",
			databaseURL: "https://firewire-test-db.firebaseio.com",
			storageBucket: "firewire-test-db.appspot.com",
			messagingSenderId: "189220152595",
			projectId: "firewire-test-db",
		},
		android: {
			clientId: "189220152595-4svpc3i8kgc8kg44mth0b3n509j69co9.apps.googleusercontent.com",
			appId: "1:189220152595:android:bd0ece7dce004cfe3366e6", // google app id
			apiKey: "AIzaSyB7LzAeAZA_vQURy6YdzudIKbn-9ZtrAMo",
			databaseURL: "https://firewire-test-db.firebaseio.com",
			storageBucket: "firewire-test-db.appspot.com",
			messagingSenderId: "189220152595",
			projectId: "firewire-test-db",
		},
		web: {
			apiKey: "AIzaSyAJHVzaRP7SXOuNUfKhmPkq4gqp4YQXQCE",
			authDomain: "firewire-test-db.firebaseapp.com",
			databaseURL: "https://firewire-test-db.firebaseio.com",
			projectId: "firewire-test-db",
			storageBucket: "firewire-test-db.appspot.com",
			messagingSenderId: "189220152595",
			appId: "1:189220152595:web:a5fbba97f261d36c3366e6",
		},
		hasPeerSupport: true,
	},
	"local-858": {
		web: {
			apiKey: "AIzaSyCRh7Hd_Aml9tzfW4I4Ok2vU-ykVg478qM",
			authDomain: "local-858.firebaseapp.com",
			projectId: "local-858",
			storageBucket: "local-858.appspot.com",
			messagingSenderId: "817921793296",
			appId: "1:817921793296:web:4c44c16496b539fa2bd995",
		},
	},
	"local-575": {
		web: {
			apiKey: "AIzaSyDglQork4uLDxXVppE8sWTg0VOvSYTDPlk",
			authDomain: "local-575.firebaseapp.com",
			projectId: "local-575",
			storageBucket: "local-575.appspot.com",
			messagingSenderId: "955967417312",
			appId: "1:955967417312:web:e1302f30c6974a4e6a1985",
		},
	},
	"local-119-dep": {
		web: {
			apiKey: "AIzaSyBNjJEMQcgDgcxzgI8rdiYoi_ZwQcQIrec",
			authDomain: "local-119-dep.firebaseapp.com",
			projectId: "local-119-dep",
			storageBucket: "local-119-dep.appspot.com",
			messagingSenderId: "461828372044",
			appId: "1:461828372044:web:cc0e46ea9616ceda0126e9",
		},
	},
};
