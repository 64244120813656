import React, { createContext, Context } from "react";
import { ILayoutSettings } from "../../models/layout";

export type ILayoutContext = {
	settings: ILayoutSettings;
	imageWidth: number;
	imageHeight: number;
	// imageWidth100Pct: boolean;
	// imageHeight100Pct: boolean;
	onResize: (settings: ILayoutSettings) => any;
	imagesPerRow?: number;
};

const LayoutContext: Context<ILayoutContext> = createContext<ILayoutContext>({
	settings: null,
	onResize: null,
	imageWidth: 380,
	imageHeight: 389,
	// imageWidth100Pct: false,
	// imageHeight100Pct: false,
});

export type WithLayoutContextProps = {
	layoutContext: ILayoutContext;
};

export const withLayoutContext = (Component) => (props) => (
	<LayoutContext.Consumer>{(context) => <Component layoutContext={context} {...props} />}</LayoutContext.Consumer>
);

export default LayoutContext;
