import React, { Component } from "react";
import * as firebase from "firebase/app";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppContext, { APP_CONFIG } from "./AppContext";
// import AuthContext from "../../laco-common/auth/AuthContext";
import { FirebaseProvider } from "../../laco-common/firebase/context";
import ClientFirebase from "../../laco-common/firebase/web";
import { ClientFirebase as ClientFirebaseC } from "../../laco-common";
import { rnClientsConfig } from "../../laco-common/firebase/clients";
import { firewireApp } from "../../firewire";
import { IFirebaseContext } from "../../laco-common/base/firebase";

// exposes context used as such:
// this.context.auth
// this.context.firebase
// this.context.config

type AppProviderState = {
	loading: boolean;
	secondClientFirebase: Partial<ClientFirebaseC>;
};

class AppProvider extends Component<any, AppProviderState> {
	state = {
		loading: true,
		secondClientFirebase: null,
	};

	componentDidMount() {
		const SITE_TENANT: string = process.env.REACT_APP_SITE_TENANT;
		if (SITE_TENANT) {
			const fConfig = rnClientsConfig[SITE_TENANT]["web"];
			const siteFirebaseApp = firebase.initializeApp(fConfig, "siteFirebaseApp");
			this.setState({
				secondClientFirebase: {
					getFirebase: () => siteFirebaseApp,
					init: (firebaseKey: string) => {
						return Promise.resolve(siteFirebaseApp);
					},
				},
			});
		}

		this.setState({ loading: false });
	}

	shouldComponentUpdate(nextProps, nextState: AppProviderState): boolean {
		return nextState.loading !== this.state.loading;
	}

	render() {
		if (this.state.loading)
			return (
				<div className={this.props.className} style={{ width: "100%", height: "100%", textAlign: "center" }}>
					<CircularProgress style={this.props.loaderStyle || { margin: "20vh auto" }} />
				</div>
			);

		const firebaseContext: IFirebaseContext = {
			tenant: APP_CONFIG.tenant,
			userPayload: null,
			clientFirebase: ClientFirebase,
			secondClientFirebase: this.state.secondClientFirebase,
			firewireApp,
		};

		return (
			// <AuthContext.Consumer>
			// 	{(auth) => {
			// 		const firebaseContext: IFirebaseContext = {
			// 			tenant: APP_CONFIG.tenant,
			// 			userPayload: auth.userPayload,
			// 			clientFirebase: ClientFirebase,
			// 		};
			// 		return (
			<FirebaseProvider value={firebaseContext}>
				{/* <FirebaseConsumer>
								{(firebase) => ( */}
				<AppContext.Provider
					value={{
						config: APP_CONFIG,
						firebase: firebaseContext,
					}}
				>
					{this.props.children}
				</AppContext.Provider>
				{/* )} */}
				{/* </FirebaseConsumer> */}
			</FirebaseProvider>
			// 		);
			// 	}}
			// </AuthContext.Consumer>
		);
	}
}

export default AppProvider;
