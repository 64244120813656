import React, { Component } from "react";
import AuthContext from "./AuthContext";
import { IUserAuthPayload, IUserProfileData, UserRole } from "../base/models/user";
import { getUserProfile, getUserPayload, updateUserProfile, IAuthStateProps } from "../base/auth";
import NotificationsService from "../services/notifications";
import { LOCAL_STORAGE_KEYS } from "../../models/localStorage";

type AuthProviderState = IAuthStateProps & {
	refresh: number;
};

class AuthProvider extends Component<
	{
		firebase: firebase.app.App;
		allowedTenants?: Set<string>;
		allowSubscribers?: boolean;
		loadUserTenantFirebase?: (payload: IUserAuthPayload) => Promise<any>;
	},
	AuthProviderState
> {
	private isRegistering: boolean = false;

	state = {
		uid: undefined,
		isAuthenticated: false,
		userPayload: null,
		loading: true,
		error: null,
		refresh: 0,
		tenant: undefined,
		tenantFirebase: null,
	};

	componentDidMount() {
		console.log("AuthProvider.componentDidMount()");
		this.init();
	}

	shouldComponentUpdate(nextProps, nextState: AuthProviderState): boolean {
		console.debug("AuthProvider.shouldComponentUpdate(): nextState, this.state", nextState, this.state);

		return (
			this.state.isAuthenticated !== nextState.isAuthenticated ||
			this.state.uid !== nextState.uid ||
			this.state.loading !== nextState.loading ||
			this.state.refresh !== nextState.refresh ||
			this.state.error !== nextState.error ||
			this.state.userPayload !== nextState.userPayload ||
			this.state.tenant !== nextState.tenant ||
			this.state.tenantFirebase !== nextState.tenantFirebase
		);
	}

	async init() {
		const u: firebase.User | null = this.props.firebase.auth().currentUser;
		if (u) {
			await this.updateUserState(u);
			return;
		}

		this.props.firebase.auth().onAuthStateChanged(async (authUser: firebase.User | null) => {
			console.log("onAuthStateChanged authUser:", authUser);
			if (!this.isRegistering) await this.updateUserState(authUser);
		});
	}

	async login(email: string, password: string) {
		return this.props.firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(async (credential: firebase.auth.UserCredential) => {
				console.log("logged in:", credential.user);
				const user: firebase.User = credential.user;
				return this.updateUserState(user);
			})
			.catch(async (error: firebase.FirebaseError) => {
				console.log(error);
				await this.updateUserState(null, error);
				return Promise.reject(error);
			});
	}

	async logout() {
		await this.props.firebase.auth().signOut();
		await this.updateUserState(null);
	}

	async updateUserState(user: firebase.User, error?: firebase.FirebaseError): Promise<IUserProfileData> {
		console.log("updateUserState: user", user);
		if (!user) {
			// logged out
			this.setState({
				uid: undefined,
				isAuthenticated: false,
				userPayload: null,
				tenant: undefined,
				tenantFirebase: null,
				loading: false,
				error,
			});
			return null;
		}

		const profile: IUserProfileData = await getUserProfile(
			user.uid,
			this.props.firebase,
			this.props.allowSubscribers
		);

		if (
			!profile?.tenant ||
			(this.props.allowedTenants && !this.props.allowedTenants.has(profile?.tenant) && !profile?.superAdmin)
		) {
			if (!profile?.tenant) {
				NotificationsService.error("Invalid tenant. Please contact your administrator.");
			}

			// user tenant invalid
			const err = {
				name: "Error",
				message: "Invalid Credentials",
				code: "invalid-tenant",
			};
			// NotificationsService.error(
			// 	"Please verify your credentials or reset your password. If the issue persists contact +1818-535-6093."
			// );
			this.setState({
				uid: undefined,
				isAuthenticated: false,
				userPayload: null,
				tenant: undefined,
				loading: false,
				error: err,
			});
			await this.props.firebase.auth().signOut();
			return Promise.reject(err);
		}

		const userPayload: IUserAuthPayload = getUserPayload(user, profile, null); // simba
		if (this.props.loadUserTenantFirebase) {
			const tenantFirebase = await this.props.loadUserTenantFirebase(userPayload);
			this.setState({ tenantFirebase });
		}

		this.setState({
			uid: user.uid,
			isAuthenticated: true,
			userPayload,
			tenant: profile?.tenant,
			loading: false,
			error,
		});

		return profile;
	}

	private setAnonymousUserEmail(email: string) {
		console.log("AuthProvider.setAnonymousUserEmail(): email:", email);
		localStorage.setItem(LOCAL_STORAGE_KEYS.ANONYMOUS_USER_EMAIL, email);
	}

	private getAnonymousUserEmail() {
		return localStorage.getItem(LOCAL_STORAGE_KEYS.ANONYMOUS_USER_EMAIL);
	}

	private getUserIdentifier(): string {
		console.log("AuthProvider.getUserIdentifier()");
		return this.state.uid || localStorage.getItem(LOCAL_STORAGE_KEYS.ANONYMOUS_USER_EMAIL);
	}

	async updateUserProfile(profileData: Partial<IUserProfileData>, password?: string) {
		if (!this.state.uid || !this.state.userPayload || !this.state.userPayload.user)
			throw Error("No uid or userPayload for update");
		const updatedUserPayload: IUserAuthPayload = await updateUserProfile(
			this.state.uid,
			this.props.firebase,
			profileData,
			password
		);
		this.setState({
			userPayload: updatedUserPayload,
			refresh: this.state.refresh + 1,
		});
	}

	async registerUser(data: IUserProfileData & { password: string }, role?: UserRole): Promise<any> {
		console.log("registerUser:", data, role);
		this.isRegistering = true;
		const userCredential: firebase.auth.UserCredential = await this.props.firebase
			.auth()
			.createUserWithEmailAndPassword(data.primary_email, data.password);
		console.log("userCredential:", userCredential);
		const uid: string = userCredential.user.uid;
		let mobile_number: string = data.mobile_number;
		if (mobile_number && mobile_number.indexOf("+1") === -1) {
			mobile_number = "+1" + mobile_number;
		}
		const params = {
			uid,
			...data,
			mobile_number,
			role,
			active: role !== "subscriber",
			tenant: process.env.REACT_APP_TENANT,
		};
		await this.props.firebase.functions().httpsCallable("createUser")(params);
		this.isRegistering = false;
		return this.login(data.primary_email, data.password);
	}

	render() {
		return (
			<AuthContext.Provider
				value={{
					...this.state,
					tenant: this.state.userPayload ? this.state.userPayload.profile?.tenant : undefined,
					login: (username: string, password: string) => {
						return this.login(username, password);
					},
					logout: () => {
						return this.logout();
					},
					setAnonymousUserEmail: (email: string) => this.setAnonymousUserEmail(email),
					getAnonymousUserEmail: () => this.getAnonymousUserEmail(),
					getUserIdentifier: () => this.getUserIdentifier(),
					getAuthApp: (): firebase.app.App => this.props.firebase,
					updateUserProfile: (profileData: Partial<IUserProfileData>, password?: string) => {
						return this.updateUserProfile(profileData, password);
					},
					register: (data: IUserProfileData & { password: string }, role?: UserRole): Promise<any> => {
						return this.registerUser(data, role);
					},
				}}
			>
				{this.props.children}
			</AuthContext.Provider>
		);
	}
}

export default AuthProvider;
