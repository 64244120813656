// import { StorageConfig, PathType } from "./Storage.ts__";
import { EntityType } from "./Entity";
import ClientFirebase from "../laco-common/firebase/web";
import { ISiteMedia } from "./cms";
import { convertDateFromFirebase } from "../utils/date-utils";
import { StorageConfig } from "../laco-common/firebase/storage";
import { IFile, FileType } from "../laco-common/base/models/IFile";
const fileExtension = require("file-extension");

const THUMB_EXT = ".webp";

export const EMPTY_IMG: string = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D";

// dana update
const getThumbFilename = (filename: string): string => {
	if (filename?.startsWith("thumb@")) return filename;
	return `thumb@${filename}`;
};

export const getThumbFilename2 = (media: { filename: string; fileType: FileType }): string => {
	let filename: string = media.filename;
	if (filename?.startsWith("thumb@")) return filename;
	// if (media.fileType === FileType.VIDEO) {
	filename = replaceExt(filename);
	return filename;
	// }
	// return `thumb@${filename}`;
};

// TODO: dana remove
export enum PathType {
	GALLERY = "Gallery Images",
	PROFILE = "profile",
	VIDEO = "video",
	AUDIO = "audio",
}

export interface IImage extends IFile {
	desc?: string;
	width?: number;
	height?: number;
}

export function createIFile(file: File, url: string, uploadTask, uploadFileRef, isProfile?: boolean): IFile {
	let params = {};
	let fileType: FileType = FileType.IMAGE;
	if (file.type.startsWith("video")) {
		fileType = FileType.VIDEO;
		params = {
			video: file.name,
			thumbUrl: url.replace(file.name, getThumbFilename(replaceExt(file.name))),
		};
	} else if (file.type.startsWith("audio")) {
		fileType = FileType.AUDIO;
	}

	return {
		...params,
		filename: file.name,
		url,
		uploadTask,
		uploadFileRef,
		order: fileType === FileType.VIDEO ? 0 : 1,
		fileType: fileType,
		preview: true, // TODO
		isProfile: isProfile,
	};
}

export function fileToDBObject(file: IFile): any {
	const temp = Object.assign({}, file);
	delete temp.docId;
	// delete temp.dbCollection;
	delete temp.uploadTask; // TODO - generic method
	delete temp.uploadFileRef;
	delete temp.url;
	for (const key in temp) {
		if (temp[key] === undefined || temp[key] === null) delete temp[key]; // remove null and undefined
	}
	if (file.fileType === FileType.VIDEO) {
		// temp.filename = replaceExt(file.filename); // filename is image
	}
	return temp;
}

// replace extension with image name
function replaceExt(filename: string): string {
	const fileExtension = filename?.substr(filename.lastIndexOf("."));
	return filename?.replace(fileExtension, THUMB_EXT);
}

export class ImageMedia implements IImage {
	public readonly fileType: FileType = FileType.IMAGE;
	public readonly url: string;
	public readonly title?: string;
	public readonly filename: string;
	public readonly docId?: string;
	public order?: number;
	public preview?: boolean;
	public thumbUrl?: string;
	public desc?: string;
	public width?: number;
	public height?: number;

	public constructor(filename: string, url: string, title?: string, docId?: string) {
		this.filename = filename;
		this.url = url;
		this.title = title;
		this.docId = docId;
	}

	// public getFilename(): string {
	// 	return this.filename;
	// }

	// helper to create image
	public static create(filename: string, url: string, title?: string, docId?: string): ImageMedia {
		const image: IImage = new ImageMedia(filename, url, title, docId);
		return image;
	}
}

export class VideoMedia extends ImageMedia {
	public readonly fileType: FileType = FileType.VIDEO;
	public readonly video: string;
	public readonly vidFormat?: string;

	public constructor(
		filename: string,
		url: string,
		title: string,
		video: string,
		vidFormat?: string,
		docId?: string
	) {
		super(filename, url, title, docId);
		this.video = video;
		this.vidFormat = vidFormat;
	}

	public getFilename(): string {
		return this.video;
	}

	// helper to create video
	public static createVideo(
		filename: string,
		url: string,
		title: string,
		video: string,
		vidFormat?: string,
		docId?: string
	): VideoMedia {
		const videoMedia: VideoMedia = new VideoMedia(filename, url, title, video, vidFormat, docId);
		return videoMedia;
	}
}

// factory method
export function createIImage(
	data: any,
	id: string,
	entityType: EntityType,
	entityId: string,
	isProfileImg?: boolean
): IImage {
	let media: IImage;
	const filename: string = isProfileImg ? data.profileImg : data.filename;
	// const vidThumbnail: boolean = !isProfileImg && data.vidThumbnail;
	const vid: string = !isProfileImg && (data.video || data.fileType === FileType.VIDEO);

	const fullUrl: string = constructImageUrl(filename, entityType, entityId, vid, false, isProfileImg);
	const thumbUrl: string = constructImageUrl(filename, entityType, entityId, vid, true);

	if (vid) {
		// video
		media = VideoMedia.createVideo(filename, fullUrl, data.title, vid, data.vidFormat, id);
	} else {
		media = ImageMedia.create(filename, fullUrl, data.title, id);
	}

	media.thumbUrl = thumbUrl;
	media.desc = data.desc;
	media.order = data.order;
	media.preview = data.preview;
	media.isProfile = isProfileImg;

	return media;
}

export function constructImageUrl(
	filename: string,
	entityType: EntityType,
	entityId: string,
	videoFile?: string,
	isThumb?: boolean,
	isProfileImg?: boolean
): string {
	let url: string;
	let fileRef: string = filename;
	let folderRef: string = isProfileImg ? PathType.PROFILE : PathType.GALLERY;
	if (isThumb) {
		fileRef = getThumbFilename(videoFile ? replaceExt(filename) : filename);
	}
	if (videoFile) {
		if (!isThumb) {
			fileRef = videoFile;
		}
		folderRef = PathType.VIDEO;
	}

	let pathPrefix: string = "";
	// switch (entityType) {
	// 	case EntityType.PERSON:
	// 		pathPrefix = StorageConfig.personnelImageUrl;
	// 		break;
	// 	case EntityType.STATION:
	// 		pathPrefix = StorageConfig.stationImageUrl;
	// 		break;
	// 	case EntityType.INCIDENT:
	// 		pathPrefix = StorageConfig.incidentImageUrl;
	// 		break;
	// }

	url = "dana TODO"; //StorageConfig.constructUrl(pathPrefix, entityId, folderRef, fileRef);

	return url;
}

export function toStationIImage(doc: any, stationId: string, isProfileImg?: boolean) {
	return createIImage(doc.data(), doc.id, EntityType.STATION, stationId, isProfileImg);
}

export function toIncidentIImage(doc: any, incidentId: string) {
	return createIImage(doc.data(), doc.id, EntityType.INCIDENT, incidentId);
}

export function toPersonIImage(doc: any, personId: string, isProfileImg?: boolean) {
	return createIImage(doc.data(), doc.id, EntityType.PERSON, personId, isProfileImg);
}

// export function getThumbName(filename: string, doReplaceExt?: boolean): string {
// 	const name: string = doReplaceExt ? replaceExt(filename) : filename;
// 	return StorageConfig.getThumbFilename(350, name);
// }

// export function getMediaPath(baseUrl: string, mediaVal: string, mediaPath: string, docId: string) {
// 	if (!mediaVal) return null;
// 	return StorageConfig.constructUrl(baseUrl, docId, mediaPath, mediaVal);
// }

export const getSiteMedia = (media: ISiteMedia, folder1?: string, folder2?: string): IImage => {
	if (!media) return null;
	const fileType: FileType = media.fileType || getFileType(media.filename);
	return {
		fileType,
		filename: media.filename,
		url:
			folder1 && folder2
				? ClientFirebase.getStoragePath2("site", folder1, folder2, media.filename)
				: ClientFirebase.getStoragePath("site", folder1 || "media", media.filename),
		thumbUrl:
			folder1 && folder2
				? ClientFirebase.getStoragePath2(
						"site",
						folder1,
						folder2,
						getThumbFilename2({ filename: media.filename, fileType })
				  )
				: ClientFirebase.getStoragePath(
						"site",
						folder1 || "media",
						getThumbFilename2({ filename: media.filename, fileType })
				  ),
		...media,
		mediaDate: convertDateFromFirebase(media.mediaDate),
	};
};

export const getSiteMedia2 = (media: ISiteMedia, ...paths: string[]): IImage => {
	if (!media) return null;
	const fileType: FileType = media.fileType || getFileType(media.filename);
	const thumbFileName = getThumbFilename2({ filename: media.filename, fileType });
	return {
		fileType,
		filename: media.filename,
		url: ClientFirebase.getStorageUrl("site", ...paths, media.filename),
		thumbUrl: ClientFirebase.storage._baseUrl + ClientFirebase.getStorageUrl("site", ...paths, thumbFileName),
		...media,
		mediaDate: convertDateFromFirebase(media.mediaDate),
	};
};

export function getFileType(filename: string): FileType {
	const ext: string = (fileExtension(filename) || "").toLowerCase();
	console.debug("getFileType() ext:", ext);
	switch (ext) {
		case "xlr":
		case "odr":
		case "xls":
		case "xlsx":
		// iconClass += "far fa-file-excel";
		// break;
		case "csv":
		// iconClass += "fas fa-file-csv";
		// break;
		case "pdf":
		// iconClass += "far fa-file-pdf";
		// break;
		case "doc":
		case "docx":
		// iconClass += "far fa-file-word";
		// break;
		case "ppt":
		case "pps":
		case "pptx":
			// iconClass += "far fa-file-powerpoint";
			return FileType.FILE;
		case "ai":
		case "gif":
		case "jpg":
		case "jpeg":
		case "bmp":
		case "ico":
		case "png":
		case "webp":
			// iconClass += "far fa-file-image";
			return FileType.IMAGE;
		case "32g":
		case "3gp":
		case "h264":
		case "avi":
		case "flv":
		case "m4v":
		case "mkv":
		case "mov":
		case "mp4":
		case "mpg":
		case "mpeg":
		case "rm":
		case "swf":
		case "wmv":
			// iconClass += "far fa-file-video";
			return FileType.VIDEO;
		case "aif":
		case "cda":
		case "mid":
		case "midi":
		case "mpa":
		case "ogg":
		case "wav":
		case "wma":
		case "wpl":
		case "mp3":
		case "m4a":
		case "aac":
			// iconClass += "far fa-file-audio";
			return FileType.AUDIO;
		case "txt":
		default:
			// iconClass += "far fa-file";
			return FileType.FILE;
	}
}

export function getThumbName(filename: string, doReplaceExt?: boolean): string {
	const name: string = doReplaceExt ? replaceExt(filename) : filename;
	return StorageConfig.getThumbFilename(350, name);
}
