import { Reducer } from "react";
import { IAction } from "../actions";
import { HonorUActionTypes } from "../actions/honoru";
import { IState } from ".";
import { IHonorUProfile } from "../../models/honoru";
import { IImage } from "../../models/Image";

export interface IHonorUState {
	landingHeadline: string;
	landingProfile: IHonorUProfile;
	landingMedia: IImage;
	profiles: IHonorUProfile[];
}

export const initialState: IHonorUState = {
	landingHeadline: "",
	landingProfile: null,
	landingMedia: null,
	profiles: [],
};

export const reducer: Reducer<IHonorUState, IAction> = (
	state: IHonorUState = initialState,
	action: IAction
): IHonorUState => {
	switch (action.type) {
		case HonorUActionTypes.LOAD_PROFILES: {
			const profiles: IHonorUProfile[] = action.payload;
			return {
				...state,
				profiles,
			};
		}
		case HonorUActionTypes.LOAD_HEADLINE: {
			const landingHeadline: string = action.payload;
			return {
				...state,
				landingHeadline,
			};
		}
		case HonorUActionTypes.LOAD_FEATURED: {
			const { landingProfile, landingMedia } = action.payload;
			return {
				...state,
				landingProfile,
				landingMedia,
			};
		}
		default:
			return state;
	}
};

export function mapHonorUStateToProps(state: IState): IHonorUState {
	return state.honorUStore;
}
