import { IBaseEntity } from "../../laco-common";
import { Address } from "../../laco-common/base/models/address";

export type IPSFacility = IBaseEntity & {
	name: string;
	phone: string;
	address: Address;
	showZipCodeOnly?: "Yes" | "No";
	zipCode: number;
	resources: string[]; // dana TODO
	workcomp?: "Yes" | "No";
	telemedicine?: "Yes" | "No";
};

export const getFacilityLink = (facility: IPSFacility): string => `/ps/facilities/${facility.id}`;
