import { Reducer } from "react";
import { IAction } from "../actions";
import { AudioActionTypes } from "../actions/audio";
import { IState } from ".";
import { ISiteAudioContent } from "../../models/cms";

export interface IAudioState {
	audioFilesMap: { [pageId: string]: ISiteAudioContent };
}

export const initialState: IAudioState = {
	audioFilesMap: null,
};

export const reducer: Reducer<IAudioState, IAction> = (
	state: IAudioState = initialState,
	action: IAction
): IAudioState => {
	switch (action.type) {
		case AudioActionTypes.LOAD_AUDIO_FILE: {
			const { pageId, audioFilesConfig } = action.payload;
			const audioFilesMap: { [pageId: string]: ISiteAudioContent } = { ...state.audioFilesMap } || {};
			return {
				...state,
				audioFilesMap: {
					...audioFilesMap,
					[pageId]: { ...audioFilesConfig },
				},
			};
		}
		case AudioActionTypes.LOAD_AUDIO_FILES: {
			const { audioFilesConfig } = action.payload;
			return {
				...state,
				audioFilesMap: { ...audioFilesConfig },
			};
		}

		default:
			return state;
	}
};

export function mapAudioStateToProps(state: IState, props?: any): IAudioState {
	return state.audioStore;
}
