import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import PlatformUtils from "./platform";
import NotificationsService from "../laco-common/services/notifications";
import { convertDateFromFirebase } from "./date-utils";
import { getDateStringInTimezone } from "../laco-common/base/utils";
const queryString = require("query-string");
const numeral = require("numeral");

export function getRandomInt(max: number): number {
	return Math.floor(Math.random() * Math.floor(max));
}

export function copyToClipboard(val: string) {
	let range: Range;
	let selection: Selection | any;
	const el: any = document.createElement("textarea");
	el.value = val;
	document.body.appendChild(el);

	if (PlatformUtils.IOS_USER) {
		range = document.createRange();
		range.selectNodeContents(el);
		selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		el.setSelectionRange(0, 999999);
	} else {
		el.select();
	}

	document.execCommand("copy");
	document.body.removeChild(el);
}

export const isEmpty = (value: any): boolean => {
	return value === null || value === undefined || value === "";
};

export const isEmptyValue = (value: any): boolean => {
	return isEmpty(value) || isEmptyContent(value);
};

export const isEmptyContent = (value: any): boolean => {
	return value && value.props && value.props["data-corre-empty"] == true;
};

export const isEmptyHtmlContent = (value: any): boolean => {
	return !value || value == "<p></p>" || value == "<p><br></p>";
};

export const EMPTY_CONTENT: React.ReactNode = <span data-corre-empty={true}></span>;

export const sleep1 = (m) => new Promise((r) => setTimeout(r, m)); // test async with sleep

export function sleep(fn, millisec, params?: any) {
	return new Promise((resolve) => {
		// wait 3s before calling fn(par)
		setTimeout(() => resolve(fn(params)), millisec || 3000);
	});
}

export function handleAppError(error: Error) {
	NotificationsService.error(error.message.replace("Firebase", ""));
	console.error(error);
}

export function waitForElement(elSelector: string, callback: (element: Element) => any): void {
	_waitForElements(elSelector, false, callback);
}

export function waitForElements(elSelector: string, callback: (elements: Element[]) => any): void {
	_waitForElements(elSelector, true, callback);
}

function _waitForElements(
	elSelector: string,
	selectAll: boolean,
	callback: (element: Element | Element[]) => any
): void {
	let el = null;
	let timeout = setTimeout(() => {
		if (selectAll) {
			el = Array.from(document.querySelectorAll(elSelector));
			if (el.length > 0) {
				callback(el);
				clearTimeout(timeout);
			} else {
				_waitForElements(elSelector, selectAll, callback);
			}
		} else {
			el = document.querySelector(elSelector);
			if (el) {
				callback(el);
				clearTimeout(timeout);
			} else {
				_waitForElements(elSelector, selectAll, callback);
			}
		}
	}, 100);
}

export function waitForObject(paramName: string, callback: (obj) => any): void {
	let obj = null;
	let timeout = setTimeout(() => {
		obj = window[paramName];
		if (obj) {
			callback(obj);
			clearTimeout(timeout);
		} else {
			waitForObject(paramName, callback);
		}
	}, 100);
}

export function getViewImageLink(filename: string): string {
	return `viewImage=${filename}`;
}

export function getViewImage(location: { search: string }): string {
	const parsed: any = queryString.parse(location.search);
	return parsed.viewImage; // filename
}

export function stringifyQuerystring(parsed: any): string {
	let str = "";
	for (const key in parsed) {
		if (parsed[key]) {
			str += `${key}=${encodeURI(parsed[key])}&`;
		}
	}
	return str;
	// return queryString.stringify(parsed);
}

export function formatPhoneNumber(phoneNumberString) {
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		const intlCode = match[1] ? "+1 " : "";
		return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
	}
	return phoneNumberString;
}

export function sortBy(arr: any[], field: string, desc?: boolean): any[] {
	return arr.sort((a, b) => {
		let comparison = 0;
		if (a[field] > b[field]) comparison = desc ? -1 : 1;
		else if (b[field] > a[field]) comparison = desc ? 1 : -1;
		return comparison;
	});
}

export function renderGridOrphans(count: number, template) {
	let elm = [];
	for (let i = 0; i < count; i++) {
		elm.push(template);
	}
	return elm;
}

export function convertObjectToFirebaseObj(obj: any) {
	let data = {};
	for (let prop in obj) {
		const value: any = obj[prop];
		if (value !== undefined && !isObject(value) && !Array.isArray(value)) {
			data[prop] = value;
		}
	}
	return data;
}

function isObject(a) {
	return a != null && (typeof a === "object" || a.constructor === Object);
}

export function toCamelCase(str) {
	return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
		if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
		return index === 0 ? match.toLowerCase() : match.toUpperCase();
	});
}

export const capitalize = (s) => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export function formatKB(no: number) {
	if (no === 0) return "0";
	if (!no) return no;
	return numeral(no).format("0.0b");
}

export function formatCurrency(no: number) {
	if (no === 0) return "0";
	if (!no) return no;
	return numeral(no).format("$0,0");
}

// removes undefined values, converts date to time
export function formatForFirebase<T>(data: T): T {
	for (let key in data) {
		if (data[key] === undefined) {
			delete data[key];
		} else if (typeof data[key] === "object") {
			if (data[key] && (data[key] as any).getTime) {
				data[key] = (data[key] as any).getTime();
			} else data[key] = formatForFirebase(data[key]);
		}
	}
	return data;
}

export function stringify(value: any, htmlFormat?: boolean, key?: string): string | React.ReactNode {
	if (typeof value !== "object" || value?.props) {
		if (htmlFormat && key && !value?.props) {
			return formatify(key, value);
		}
		return value;
	}
	if (Array.isArray(value)) {
		const formatted: string = value.map((o) => stringify(o, htmlFormat)).join(" , ");
		// if (htmlFormat) return <span>{formatted}</span>;
		return formatted;
	} else {
		// object
		if (htmlFormat) {
			if (key == "dueDate") {
				return getDateStringInTimezone(convertDateFromFirebase(value).getTime());
			}
			return (
				<ul>
					{Object.keys(value).map((prop: string) => {
						const val = formatify(prop, value[prop]);
						return (
							<li key={prop}>
								<b style={{ textTransform: "capitalize" }}>{prop}: </b>
								{val}
							</li>
						);
					})}
				</ul>
			);
		} else {
			let val = "";
			try {
				val = JSON.stringify(value);
			} catch (e) {
				console.error("Error parsing value:", value, e);
			}
			return val;
		}
	}
}

export function formatify(key: string, value: any) {
	let val = value;
	switch (key) {
		case "imageUrl": {
			val = (
				<div>
					<a href={val} target="_blank">
						<img src={val} width="200" />
					</a>
					<div className="mt-1">
						<a href={val} target="_blank">
							{val}
						</a>
					</div>
				</div>
			);
			break;
		}
		case "dueDate": {
			val = getDateStringInTimezone(convertDateFromFirebase(val).getTime());
			break;
		}
		default: {
			return val;
		}
	}

	console.log("formatify: key, value, val:", key, value, val);
	return val;
}

export function scrollToTop(selector: string = ".header-container") {
	let node = document.querySelector(selector);
	if (node) {
		scrollIntoView(node, {
			// scrollMode: "if-needed",
			block: "nearest",
			inline: "nearest",
		});
		return;
	}
	// node = document.querySelector("");
	// scrollIntoView(node, {
	// 	scrollMode: "if-needed",
	// 	// block: "nearest",
	// 	// inline: "nearest",
	// 	block: "center",
	// 	inline: "center",
	// });
	// block: "center",
	// inline: "center",
	// behavior: "smooth"
}
