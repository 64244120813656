import React from "react";
import { Platform, Alert, AlertButton } from "react-native";
import { ReplaySubject } from "rxjs";

export interface NotificationPayload {
  message: string;
  level: "success" | "error" | "warning" | "info";
  autoHideDuration?: number; // in milliseconds
  children?: React.ReactNode;
}

export type NotificationParams = {
  autoHideDuration?: number;
  mobileCallback?: () => any;
};

export interface INotificationsService {
  notifications?;
  success(message: string, children?, params?: NotificationParams): void;
  info(message: string, children?, params?: NotificationParams): void;
  error(message: string, children?, params?: NotificationParams): void;
  warning(message: string, children?, params?: NotificationParams): void;
}

class NotificationsServiceWeb implements INotificationsService {
  public notifications = new ReplaySubject<NotificationPayload>(1);

  public success(
    message: string,
    children?,
    params?: NotificationParams
  ): void {
    this.notifications.next({ message, level: "success", children, ...params });
  }

  public info(message: string, children?, params?: NotificationParams): void {
    this.notifications.next({ message, level: "info", children, ...params });
  }

  public error(message: string, children?, params?: NotificationParams): void {
    this.notifications.next({ message, level: "error", children, ...params });
  }

  public warning(
    message: string,
    children?,
    params?: NotificationParams
  ): void {
    this.notifications.next({ message, level: "warning", children, ...params });
  }
}

class NotificationsServiceMobile implements INotificationsService {
  private getAlertBtn(params?: NotificationParams) {
    if (!params || !params.mobileCallback) return undefined;
    const btn: AlertButton = {
      onPress: params.mobileCallback,
      style: "default"
    };
    return [btn];
  }

  public success(
    message: string,
    children?,
    params?: NotificationParams
  ): void {
    Alert.alert("Success", message, this.getAlertBtn(params));
  }

  public info(message: string, children?, params?: NotificationParams): void {
    Alert.alert("Info", message, this.getAlertBtn(params));
  }

  public error(message: string, children?, params?: NotificationParams): void {
    Alert.alert("Error", message, this.getAlertBtn(params));
  }

  public warning(
    message: string,
    children?,
    params?: NotificationParams
  ): void {
    Alert.alert("Warning", message, this.getAlertBtn(params));
  }
}

const Singleton = (() => {
  let _notificationsService: INotificationsService;

  return {
    getInstance: (): INotificationsService => {
      if (!_notificationsService) {
        if (Platform.OS === "web")
          _notificationsService = new NotificationsServiceWeb();
        else _notificationsService = new NotificationsServiceMobile();
      }
      return _notificationsService;
    }
  };
})();

export default Singleton.getInstance();
