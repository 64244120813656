import React, { useState } from "react";
import NumberFormat from "react-number-format";

export const PERCENTAGE_MAX_LENGTH: number = 20;

export const onPercentageInput = (e: any) => {
	e.target.value = e.target.value.toString().slice(0, PERCENTAGE_MAX_LENGTH);
};

export function NumberFormatPercentage(props) {
	const {
		inputRef,
		onChange,
		min,
		max,
		type,
		onValueChange,
		setInputFocus,
		defaultValue,
		value,
		onBlur,
		onFocus,
		...other
	} = props;
	const [focused, setFocused] = useState<boolean>(false);
	let numberValue: number = value || defaultValue;

	return (
		<NumberFormat
			placeholder="0%"
			{...other}
			value={props.value}
			getInputRef={
				inputRef ||
				((el) => {
					if (el && setInputFocus && !focused) {
						setTimeout(() => {
							if (!focused) {
								el.focus();
								el.select();
								setFocused(true);
							}
						}, 0);
					}
				})
			}
			onValueChange={
				onValueChange ||
				((values) => {
					console.log("Number format percentage values:", values);
					numberValue = values.floatValue;
				})
			}
			onBlur={(e) => {
				onChange({ target: { value: numberValue } });
			}}
			thousandSeparator
			isNumericString
			suffix="%"
			isAllowed={(values) => {
				const { formattedValue, floatValue } = values;
				return formattedValue === "" || (floatValue <= (max || 100) && floatValue >= (min || 0));
			}}
		/>
	);
}

export function NumberFormatComma(props) {
	const {
		inputRef,
		onChange,
		min,
		max,
		isInt,
		onValueChange,
		setInputFocus,
		type,
		defaultValue,
		value,
		onBlur,
		onFocus,
		...other
	} = props;
	const [focused, setFocused] = React.useState<boolean>(false);
	let numberValue: number = value || defaultValue;

	const validateNum = (value, formattedValue): boolean => {
		if (min !== undefined && value < min) return false;
		if (max !== undefined && value > max) return false;
		if (isInt && formattedValue.indexOf(".") > -1) return false;
		return true;
	};

	return (
		<NumberFormat
			placeholder="0.00"
			{...other}
			value={props.value}
			getInputRef={
				inputRef ||
				((el) => {
					if (el && setInputFocus && !focused) {
						setTimeout(() => {
							if (!focused) {
								el.focus();
								el.select();
								setFocused(true);
							}
						}, 0);
					}
				})
			}
			onValueChange={
				onValueChange ||
				((values) => {
					console.log("Number format comma values:", values);
					numberValue = values.floatValue;
				})
			}
			onBlur={(e) => {
				onChange({ target: { value: numberValue } });
			}}
			thousandSeparator
			isNumericString
			step={isInt ? 1 : 0.1}
			isAllowed={
				min !== undefined || max !== undefined
					? (values) => {
							const { formattedValue, floatValue } = values;
							return formattedValue === "" || validateNum(floatValue, formattedValue);
					  }
					: undefined
			}
		/>
	);
}

export function NumberFormatInt(props) {
	return <NumberFormatComma {...props} placeholder="0" isInt={true} />;
}

export function NumberFormatPhone(props) {
	const { inputRef, onChange, type, onValueChange, setInputFocus, defaultValue, value, onBlur, onFocus, ...other } =
		props;
	const [focused, setFocused] = useState<boolean>(false);
	// const [value, setValue] = useState<string>(props.value);
	let cleanValue: string = value || defaultValue;

	return (
		<NumberFormat
			{...other}
			value={cleanValue}
			getInputRef={
				inputRef ||
				((el) => {
					if (el && setInputFocus && !focused) {
						setTimeout(() => {
							if (!focused) {
								el.focus();
								el.select();
								setFocused(true);
							}
						}, 0);
					}
				})
			}
			onValueChange={
				onValueChange ||
				((values) => {
					console.log("phone values formatter:", values);
					cleanValue = values.value;
					if (cleanValue?.length === 10) {
						setTimeout(() => {
							console.log("Number Format phone firing onChange for completed number");
							onChange({ target: { value: cleanValue } });
						}, 1);
					}
				})
			}
			onBlur={(e) => {
				console.log("NumberFormat onBlur(): cleanValue:", cleanValue);
				onChange({ target: { value: cleanValue } });
			}}
			type={"tel"}
			format="+1 (###) ###-####"
			mask="_"
			isNumericString
			allowEmptyFormatting
		/>
	);
}
