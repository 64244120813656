import { Reducer } from "react";
import { IAction } from "../actions";
import { AppActionTypes } from "../actions/app";
import { IState } from ".";
import { IPage, ISitePage } from "../../laco-common/base/models/page";
import { IFirebaseValueMap } from "../../models/firebase/index";
import { ISiteTextContent, ISiteQuotesContent, ISiteCustomWidget, ISiteGalleryContent } from "../../models/cms";

export interface IAppState {
	headerImage1: string;
	activePage: IPage;
	pages: IPage[];
	textContent: ISiteTextContent[];
	quotesContent: ISiteQuotesContent[];
	customWidgets: ISiteCustomWidget[];
	galleriesContent: ISiteGalleryContent[];
	refresh: number;
	navigation: ISitePage[];
	siteConfiguration: IFirebaseValueMap;
}

export const initialState: IAppState = {
	headerImage1: "",
	activePage: null,
	pages: [],
	textContent: [],
	quotesContent: [],
	customWidgets: [],
	galleriesContent: [],
	refresh: 0,
	navigation: [],
	siteConfiguration: {},
};

export const reducer: Reducer<IAppState, IAction> = (state: IAppState = initialState, action: IAction): IAppState => {
	switch (action.type) {
		case AppActionTypes.LOAD_PAGES: {
			const { pages, navigation, headerImage1 } = action.payload;
			if (!pages || !pages.length) return state;
			return {
				...state,
				pages,
				navigation,
				headerImage1,
				refresh: state.refresh + 1,
			};
		}
		case AppActionTypes.LOAD_SITE_TXT_CONTENT: {
			const textContent: ISiteTextContent[] = action.payload;
			return {
				...state,
				textContent,
			};
		}
		case AppActionTypes.LOAD_SITE_QUOTES: {
			const quotesContent: ISiteQuotesContent[] = action.payload;
			return {
				...state,
				quotesContent,
			};
		}
		case AppActionTypes.LOAD_SITE_CUSTOM_WIDGETS: {
			const customWidgets: ISiteCustomWidget[] = action.payload;
			return {
				...state,
				customWidgets,
			};
		}
		case AppActionTypes.SET_CURRENT_PAGE: {
			const page: IPage = action.payload;
			return {
				...state,
				activePage: page,
			};
		}
		case AppActionTypes.LOAD_SITE_CONFIGURATION: {
			const siteConfiguration = action.payload;
			return {
				...state,
				siteConfiguration,
			};
		}
		default:
			return state;
	}
};

export function mapAppStateToProps(state: IState, props?: any): IAppState {
	return state.appStore;
}
