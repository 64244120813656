import { Reducer } from "react";
import { IAction } from "../actions";
import { FormActionTypes } from "../actions/form";
import { IState } from ".";
// import { IForm } from "../../laco-common";
import { ISiteForm } from "../../models/form";
import { IFormPage } from "../../laco-common/base/models/page";

export interface IFormState {
	forms: ISiteForm[];
	psForms: ISiteForm[];
	formPages: IFormPage[];
}

export const initialState: IFormState = {
	forms: null,
	psForms: null,
	formPages: [],
};

export const reducer: Reducer<IFormState, IAction> = (
	state: IFormState = initialState,
	action: IAction
): IFormState => {
	switch (action.type) {
		case FormActionTypes.LOAD_FORMS: {
			const { forms, formPages } = action.payload;
			return {
				...state,
				forms,
				formPages,
			};
		}
		case FormActionTypes.LOAD_PS_FORMS: {
			const { forms } = action.payload;
			return {
				...state,
				psForms: forms,
			};
		}
		default:
			return state;
	}
};

export function mapFormStateToProps(state: IState): IFormState {
	return state.formStore;
}
