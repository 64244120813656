import { Dimensions, Platform } from "react-native";
import { format, addMinutes } from "date-fns";

const { width, height } = Dimensions.get("window");
const STANDARD_WIDTH = 375;
const USE_FOR_BIGGER_SIZE = true;
export const IS_ANDROID = Platform.OS === "android";

export const CURRENT_WIDTH = width;
export const CURRENT_HEIGHT = height;
export const IS_DESKTOP: boolean = Platform.OS === "web" && CURRENT_WIDTH >= 768;

// const K = CURRENT_WIDTH / STANDARD_WIDTH;
const ratio = Math.min(CURRENT_WIDTH / STANDARD_WIDTH, 1.6);

export const dynamicSize = (size) => (IS_DESKTOP ? size : Math.round(ratio * size)); // increases size for smaller screens
export const dynamicSizeIncludingDesktop = (size) => Math.round(ratio * size); // increases size always

export const getFontSize = (size) => (USE_FOR_BIGGER_SIZE || CURRENT_WIDTH < STANDARD_WIDTH ? dynamicSize(size) : size);

export const hexToRgba = (hex, alpha = 1) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = `0x${c.join("")}`;
		// eslint-disable-next-line no-bitwise
		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${alpha})`;
	}
	throw new Error("Bad Hex");
};

export const numberToDecimal = (value) => {
	const parts = parseFloat(value).toFixed(2).split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return `${parts.join(".")}`;
};

export const numberToCurrency = (price) => {
	return `$${numberToDecimal(price)}`;
};

export const displayPrice = (price, price_type = "fixed") => {
	let value = `$${numberToDecimal(price.value)}`;
	if (price_type === "range") {
		value += ` - $${numberToDecimal(price.max)}`;
	}
	if (price_type === "recurring") {
		value += ` / ${price.base}`;
	}
	return value;
};

export const displayDate = (item, fieldName = "created_at") => {
	if (!item) return "";
	const dateNum = item[fieldName];
	if (!dateNum || isNaN(dateNum)) return "";
	return format(dateNum, "MMMM, d, yyyy");
};

// Pp OR PPPpp
export const displayDateTime = (datetime) => {
	if (!datetime || isNaN(datetime)) return "";
	return format(datetime, "PPPp");
};

export const displayDateTime2 = (datetime, duration?: number) => {
	if (!datetime || isNaN(datetime)) return "";
	let startDate = format(datetime, "PPp");
	if (!duration || isNaN(duration)) return startDate;
	const d = new Date(datetime);
	const endTime = addMinutes(d, duration);
	return `${startDate} - ${format(endTime, "p")}`;
};

export const displayShortStartToEnd = (start: Date, end: Date) => {
	let str: string = start ? format(start, "p") : "";
	if (end) {
		if (start) {
			str += " - ";
		}
		str += format(end, "p");
	}
	return str;
};

export const updateDynamicStateProp = (key: string, value: any): void => {
	console.log("updateDynamicStateProp: ", key, value);
	this.setState((prevState) => ({
		...prevState,
		[key]: value,
	}));
};

export function getDeepCopy<T>(data: T): T {
	try {
		return JSON.parse(JSON.stringify(data));
	} catch (err) {
		console.error("getDeepCopy failed with error:", err);
		return data;
	}
}
