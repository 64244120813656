import React, { CSSProperties, useState, useContext } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import LayoutContext, { ILayoutContext } from "../../contexts/layout/LayoutContext";
import { UIBox } from "../ui/UIComponents";
import { AddressBase } from "../../laco-common/base/models/address";
import "./Address.scss";

type AddressProps = {
	address: AddressBase;
	addressDisplay?: string;
	iconStyles?: CSSProperties;
	showMapOnHover?: boolean;
	showZipOnly: "Yes" | "No";
	zoom?: number;
	omitPin?: boolean;
};

export type AddressImageProps = Omit<AddressProps, "showZipOnly"> & {
	iconStyles?: CSSProperties;
	width?: number;
	height?: number;
	zoom?: number;
	omitPin?: boolean;
	className?: string;
	style?: CSSProperties;
};

export const AddressImage: React.FC<AddressImageProps> = (props: AddressImageProps) => {
	if ((!props.address || !props.address.formatted_address) && !props.addressDisplay) return null;
	const width: number = props.width || 600;
	const height: number = props.height || 300;
	const zoom: number = props.zoom || 16;
	const marker: string =
		props.omitPin || !props.address.zip_code
			? `&center=${props.address.formatted_address}`
			: `&markers=color:0xff6f2f%7Clabel:%7C${props.address.lat},${props.address.lng}`;
	// label can support 1 letter
	const url: string = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${width}x${height}&maptype=roadmap
	${marker}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`;
	return (
		<img
			src={url}
			className={clsx(props.className)}
			style={props.style || { maxWidth: "100%" }}
			width={props.width}
			height={props.height}
		/>
	);
};

export const UIAddress: React.FC<AddressProps> = (props: AddressProps) => {
	const [showAddress, setShowAddress] = useState<boolean>(false);
	const layoutContext: ILayoutContext = useContext(LayoutContext);
	if (!props.address || !props.address.formatted_address) return null;

	return (
		<>
			<div className="ui-address-container">
				<div
					className={clsx(
						"address-display",
						"d-inline-flex align-items-start",
						props.showMapOnHover && "gray-link inner-link"
					)}
					onMouseOver={
						layoutContext.settings.isMobileOrTabletDevice
							? undefined
							: () => {
									setShowAddress(true);
							  }
					}
					onMouseLeave={
						layoutContext.settings.isMobileOrTabletDevice
							? undefined
							: () => {
									setShowAddress(false);
							  }
					}
					onClick={
						layoutContext.settings.isMobileOrTabletDevice ? () => setShowAddress(!showAddress) : undefined
					}
				>
					<LocationOnIcon
						className="text-gray"
						style={{
							...{ fontSize: "12px", marginTop: "2px", marginRight: "4px", marginLeft: "-2px" },
							...(props.iconStyles || {}),
						}}
					/>
					<span>
						{props.addressDisplay ||
							(props.showZipOnly === "Yes"
								? `${props.address.city ? `${props.address.city}, ` : ""}${props.address.zip_code}`
								: props.address.formatted_address)}
					</span>
				</div>
			</div>
			{props.showMapOnHover && showAddress && (
				<UIBox className="address-hover-image p-2" noPadding={true}>
					<CircularProgress className="address-image-loader" />
					<AddressImage address={props.address} zoom={props.zoom} omitPin={props.omitPin} />
				</UIBox>
			)}
		</>
	);
};
