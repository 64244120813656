import { IAppContext } from "../../contexts/app/AppContext";
import { IAction, AppThunkDispatch } from ".";
import { IGetState } from "../reducers";
import { mapAuthStateToProps } from "../reducers/auth";
import { PSRole, getPSRole } from "../../models/peer-support";
import { IAuthContext } from "../../laco-common/base/auth";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { UserCMSRole } from "../../laco-common/base/models/user";
import ClientFirebase from "../../laco-common/firebase/web";
import { firewireApp } from "../../firewire";
import { loadPagesData, IPagesPayload } from "./app";

export enum AuthActionTypes {
	LOGIN = "[auth] LOGIN",
	LOGOUT = "[auth] LOGOUT",
	LOAD_CMS_ROLES = "[auth] LOAD_CMS_ROLES",
	LOAD_CMS_PAGES = "[auth] LOAD_CMS_PAGES",
	LOAD_PERMISSIONS = "[auth] LOAD_PERMISSIONS",
}

export const logout = () => async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
	const action: IAction = {
		type: AuthActionTypes.LOGOUT,
	};
	return dispatch(action);
};

export const login = (authContext: IAuthContext) => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	// check if it is the same user
	// const loadedUid: string = getState().authStore.uid;
	// if (loadedUid === authContext.userPayload?.uid) return;
	const psRole: PSRole = getPSRole(authContext, ClientFirebase);

	const action: IAction = {
		type: AuthActionTypes.LOGIN,
		payload: { uid: authContext.userPayload?.uid, psRole },
	};
	dispatch(action);
};

export const loadCMSRoles = () => async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
	const userRoles: UserCMSRole[] = await getCollection(firewireApp, "user_roles");
	const action: IAction = {
		type: AuthActionTypes.LOAD_CMS_ROLES,
		payload: { userRoles },
	};
	return dispatch(action);
};

export const loadCMSPages = () => async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
	if (!getState().authStore.userRoles) {
		await loadCMSRoles();
	}
	const cmsPagesPayload: IPagesPayload = await loadPagesData(
		firewireApp,
		"cms_pages",
		dispatch,
		AuthActionTypes.LOAD_CMS_PAGES,
		"admin/"
	);
	return cmsPagesPayload;
};

export const loadPermissions = () => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	const permissions: any[] = await getCollection(firewireApp, "permissions");
	const action: IAction = {
		type: AuthActionTypes.LOAD_PERMISSIONS,
		payload: { permissions },
	};
	return dispatch(action);
};

export const mapAuthDispatchToProps = (dispatch: AppThunkDispatch) => ({
	login: (authContext: IAuthContext) => dispatch(login(authContext)),
	logout: () => dispatch(logout()),
	loadCMSRoles: () => dispatch(loadCMSRoles()),
	loadCMSPages: () => dispatch(loadCMSPages()),
	loadPermissions: () => dispatch(loadPermissions()),
});

export type IAuthActionDispatches = ReturnType<typeof mapAuthDispatchToProps>;
export type IAuthStoreProps = ReturnType<typeof mapAuthStateToProps> & ReturnType<typeof mapAuthDispatchToProps>;
