export enum LOCAL_STORAGE_KEYS {
	DEBUG_MODE = "bw-debug-mode",
	DEBUG_MODE_STRINGIFY = "bw-debug-mode-stringify",
	MOCK_APIS = "bw-mock-apis",
	OFFLINE_MODE = "bw-offline-mode",
	CMS_MENU_OPEN = "cms-menu-open",
	STORE_FORM_ID = "bw-ecwid-form-id",
	ANONYMOUS_USER_EMAIL = "bw-user-email",
	LOGIN_REDIRECT = "bw-login-redirect",
}

export const REDUX_STORE_STATE = "bw-store-state" + process.env.REACT_APP_VERSION;
