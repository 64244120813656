import { IImage } from "./Image";
// import { ITag } from "./Tag";
// import { IUser } from "./User";
// import { PlanType } from "./Plan";

export const SYSTEM_UID: string = "SYSTEM";

export enum EntityType {
	STATION = "station",
	INCIDENT = "incident",
	PERSON = "person",
	POST = "post",
}

export enum EntityStatus {
	DRAFT = "draft",
	PENDING = "pending",
	ACTIVE = "active",
	REJECTED = "rejected",
}

export enum PaymentStatus {
	PENDING = "pending",
	PAID = "paid",
	NOT_REQUIRED = "notRequired",
}

export interface IEntity {
	id: string; // entity id
	name: string;
	dbCollection: string;
	tagId?: string; // relevant for station / incident
	entityType: EntityType;
	// planType: PlanType;
	status: EntityStatus;
	from?: Date;
	to?: Date;
	dateCreated: Date;
	dateUpdated: Date;
	summary?: string;
	// pageStatus: PageStatus;
	// media fields:
	video?: string;
	audio?: string;
	profileImg?: string;
	profilePic?: IImage;
}

export interface IEntityUserData extends IEntity {
	docId: string;
	ownerUid: string; // can be SYSTEM_UID
	ownerEmail: string;
	adminUids?: string[];
	// dateCreated?: Date;
	// dateUpdated?: Date;
	updatedByUid?: string;
	notMine?: boolean; // belongs to another user
	isSystem?: boolean;
	isAdminOwner: boolean;
	pageExists?: boolean;
	orderIds?: string[];
	orderNumbers?: number[];
	paymentStatus: PaymentStatus;
	extendedSearchFields?: string;
	deleteNotificationsCount?: number;
}

// export function createEntityUserData(user: IUser, entityType: EntityType, entityId: string, planType: PlanType): IEntityUserData {
//     const d: Date = new Date();

//     return {
//         docId: '',
//         status: EntityStatus.DRAFT,
//         ownerUid: user.uid || '',
//         ownerEmail: user.email || '',
//         adminUids: [(user.uid || '')],
//         isAdminOwner: user.isAdmin,
//         dateCreated: d,
//         dateUpdated: d,
//         updatedByUid: user.uid,
//         id: entityId,
//         name: '',
//         dbCollection: getDbCollection(entityType),
//         entityType: entityType,
//         planType: planType,
//         pageExists: false,
//         paymentStatus: PaymentStatus.PENDING,
//         orderIds: [],
//         orderNumbers: [],
//         deleteNotificationsCount: 0
//     }
// }

// export function toIEntityUserData(doc: any): IEntityUserData {
//     const entityType: EntityType = toEntityType(doc.data().entityType);
//     console.debug(doc.data());

//     const data: IEntityUserData = {
//         docId: doc.id,
//         status: (doc.data().status),
//         ownerUid: doc.data().ownerUid,
//         ownerEmail: doc.data().ownerEmail,
//         adminUids: doc.data().adminUids,
//         updatedByUid: doc.data().updatedByUid,
//         isSystem: doc.data().ownerUid === SYSTEM_UID,
//         dateCreated: (doc.data().dateCreated) ? doc.data().dateCreated.toDate() : null,
//         dateUpdated: (doc.data().dateUpdated) ? doc.data().dateUpdated.toDate() : null,
//         pageExists: doc.data().pageExists,
//         orderIds: doc.data().orderIds || [],
//         orderNumbers: doc.data().orderNumbers || [],
//         paymentStatus: doc.data().paymentStatus || PaymentStatus.PENDING,
//         deleteNotificationsCount: doc.data().deleteNotificationsCount,
//         isAdminOwner: doc.data().isAdminOwner || false,

//         // entity fields:
//         id: doc.data().id,
//         name: doc.data().name,
//         entityType: entityType,
//         planType: doc.data().planType || PlanType.BASIC,
//         dbCollection: getDbCollection(entityType)
//     };

//     data.extendedSearchFields = data.ownerEmail + ',' + data.ownerUid + ',' + data.entityType + ',' + data.planType + ',' + data.status + ',' + data.paymentStatus + ',' + data.orderIds.join() + ',';
//     data.extendedSearchFields += getEntityTypeShortName(data.entityType) + ',';
//     data.extendedSearchFields += getEntityStatusDisplay(data.status);

//     return data;
// }

// export function entityToITag(entity: IEntity): ITag {
//     return {
//         id: entity.tagId || '',
//         key: entity.id,
//         tagType: entity.entityType,
//         display: entity.name,
//         location: entity["location"] || entity["city"]
//     }
// }

export function toEntityType(key: string): EntityType {
	if (key === "profile") return EntityType.PERSON;
	return EntityType[key.toUpperCase()];
}

export function entityUrl(entityType: EntityType): string {
	if (entityType === EntityType.PERSON) return "profile";
	return entityType.toString();
}

export function getDbCollection(entityType: EntityType): string {
	switch (entityType) {
		case EntityType.STATION:
			return "station";
			break;
		case EntityType.PERSON:
			return "personnel";
			break;
		case EntityType.INCIDENT:
			return "incidents";
			break;
	}
	return "";
}

export function getEntityTypeDisplay(entityType: EntityType): string {
	switch (entityType) {
		case EntityType.INCIDENT:
			return "major incident";
		case EntityType.PERSON:
			return "firefighter";
		case EntityType.STATION:
			return "firestation";
	}
	return "";
}

export function getEntityTypeShortName(entityType: EntityType): string {
	switch (entityType) {
		case EntityType.INCIDENT:
			return "incident";
		case EntityType.PERSON:
			return "firefighter";
		case EntityType.STATION:
			return "firestation";
	}
	return "";
}

export function getEntityStatusDisplay(status: EntityStatus): string {
	switch (status) {
		case EntityStatus.DRAFT:
			return "Draft";
		case EntityStatus.ACTIVE:
			return "Published";
		case EntityStatus.PENDING:
			return "Pending Approval";
		case EntityStatus.REJECTED:
			return "Rejected";
	}
	return "";
}

export function getEntityUrl(entity: IEntity): string {
	let entityPath: string = "";

	switch (entity.entityType) {
		case EntityType.PERSON:
			entityPath = "profile";
			break;
		case EntityType.STATION:
		case EntityType.INCIDENT:
			entityPath = EntityType[entity.entityType] || EntityType[entity.entityType.toUpperCase()];
			break;
	}

	return `/${entityPath}/${entity.id}`;
}

export function getEditEntityUrl(entity: IEntityUserData): string {
	return `/user/pages/${entity.docId}`;
}

export function getEntityUrlByParams(entityId: string, entityType: EntityType): string {
	return getEntityUrl({
		id: entityId,
		entityType,
		name: "",
		status: null,
		dbCollection: "",
		dateCreated: null,
		dateUpdated: null,
		// planType: null,
	}); // TODO: remove planType
}
