import React, { useState, useContext } from "react";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import LockIcon from "@material-ui/icons/Lock";
// import LockOpenIcon from "@material-ui/icons/LockOpen";
import LayoutContext, { ILayoutContext } from "../../contexts/layout/LayoutContext";
import { DynamicFormFieldProps } from "../DynamicForm";
// import { UILightTooltip } from "../../ui/UIComponents";
import { FormLabel } from ".";
import { UIStyledTooltip } from "../../components/ui/UIComponents";
import { DynamicFormField } from "../../laco-common/base/form-validation/dynamic";
import { FormFieldType } from "../../laco-common";

export const PasswordInput: React.FC<DynamicFormFieldProps> = (props: DynamicFormFieldProps) => {
	const context: ILayoutContext = useContext(LayoutContext);
	const field: DynamicFormField = props.field;
	const [fieldType, setFieldType] = useState<FormFieldType>(
		context.settings.isMobileOrTabletDevice ? "text" : "password"
	);
	const { index, onChange, onBlur, processing, getHelperText, omitLabel, autoFocusFieldKey: focusFieldKey } = props; // variant

	return (
		<>
			<div className="w-100 position-relative">
				{!omitLabel && <FormLabel field={field} />}
				<TextField
					type={fieldType}
					variant="outlined"
					autoComplete={field.autoComplete || "new-password"}
					// label={omitLabel ? undefined : <FormLabel field={field} />}
					autoFocus={focusFieldKey ? focusFieldKey === field.key : index === 0}
					placeholder={field.placeholder}
					required={field.mandatory}
					className={"w-100 " + (props.className || "")}
					value={field.value}
					onChange={onChange}
					onBlur={onBlur}
					error={field.touched && !field.valid}
					helperText={getHelperText ? getHelperText(field) : undefined}
					disabled={field.disabled || processing}
					inputProps={{
						name: field.key,
						id: field.key,
						maxLength: field.maxLength,
						minLength: field.minLength,
						min: field.min,
						max: field.max,
						style: { paddingRight: "46px" },
					}}
				/>
				<UIStyledTooltip
					title={fieldType === "password" ? "Show Password" : "Hide Password"}
					className="position-absolute"
					style={{ right: 3, bottom: 6, zIndex: 9 }}
				>
					<IconButton
						edge="start"
						aria-label={fieldType === "password" ? "Show Password" : "Hide Password"}
						size="medium"
						onClick={(e) => {
							e.preventDefault();
							setFieldType(fieldType === "password" ? "text" : "password");
						}}
					>
						{fieldType === "password" && <VisibilityIcon fontSize="small" />}
						{fieldType === "text" && <VisibilityOffIcon fontSize="small" />}
					</IconButton>
				</UIStyledTooltip>
			</div>
		</>
	);
};

export default PasswordInput;
