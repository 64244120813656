import { Reducer } from "react";
import { IAction } from "../actions";
import { IState } from ".";
import { StripeActionTypes } from "../actions/stripe";
import { OrderItem, OrderDetails } from "../../laco-common/base/models/order";

export interface IStripeState {
	orderEmail: string;
	cartItems: OrderItem[];
	orderPlaced: OrderDetails;
	orderHistory: OrderDetails[];
}

export const initialState: IStripeState = {
	orderEmail: "",
	cartItems: [],
	orderPlaced: null,
	orderHistory: [],
	// coupon: null,
};

export const reducer: Reducer<IStripeState, IAction> = (
	state: IStripeState = initialState,
	action: IAction
): IStripeState => {
	switch (action.type) {
		case StripeActionTypes.ADD_TO_CART: {
			const payload: OrderItem = action.payload;
			let cartItems: OrderItem[];
			const foundIndex: number = (state.cartItems || []).findIndex((c: OrderItem) => c.id === payload.id);
			if (foundIndex >= 0) {
				// update
				cartItems = [...(state.cartItems || [])];
				cartItems[foundIndex] = payload;
			} else {
				// new
				cartItems = [payload, ...(state.cartItems || [])];
			}

			return {
				...state,
				cartItems,
			};
		}
		case StripeActionTypes.SET_EMAIL: {
			const email: string = action.payload;
			return {
				...state,
				orderEmail: email,
			};
		}
		case StripeActionTypes.DELETE_FROM_CART: {
			const payload: OrderItem = action.payload;
			return {
				...state,
				cartItems: [...(state.cartItems || []).filter((o: OrderItem) => o.id !== payload.id)],
			};
		}
		case StripeActionTypes.CLEAR_CART: {
			return {
				...state,
				cartItems: [],
			};
		}
		case StripeActionTypes.CLEAR_COMPLETED_ORDER: {
			return {
				...state,
				orderPlaced: null,
			};
		}
		case StripeActionTypes.LOAD_ORDERS: {
			const orderHistory: OrderDetails[] = action.payload;
			return {
				...state,
				orderHistory,
			};
		}
		case StripeActionTypes.COMPLETE_PAYMENT: {
			const orderPlaced: OrderDetails = action.payload;
			return {
				...state,
				orderPlaced,
			};
		}

		default:
			return state;
	}
};

export function mapStripeStateToProps(state: IState, props?: any): IStripeState {
	return state.stripeStore;
}
