import { IUserAuthPayload, IUserProfileData, UserRole } from "../models/user";
import { getPSRole } from "../models/roles";
import { ITenant } from "../models/tenant";
import { IClientFirebase } from "../firebase";

export interface IAuthStateProps {
	isAuthenticated: boolean;
	loading: boolean;
	userPayload: IUserAuthPayload;
	uid: string;
	tenant: ITenant;
	tenantFirebase?: {
		firebaseKey: string;
		getFirebase: () => any;
		init: (key: string) => Promise<any>;
	}; // ClientFirebase
	error?: firebase.FirebaseError;
}
export interface IAuthContext extends IAuthStateProps {
	login(email: string, password: string): Promise<any>;
	logout(callback?: any): any;
	getAuthApp(): firebase.app.App;
	getUserIdentifier(): string;
	setAnonymousUserEmail(email: string): void;
	getAnonymousUserEmail(): string;
	updateUserProfile(profileData: Partial<IUserProfileData>, password?: string): Promise<any>;
	register(data: IUserProfileData & { password: string }, role?: UserRole): Promise<any>;
}

export const getUserTooltipDisplay = (user: IUserProfileData, long?: boolean) => {
	return `${user.first_name} ${user.last_name}${long ? ` | ${user.primary_email}` : ""}`;
};

export function getUserPayload(
	user: firebase.User,
	profile: IUserProfileData,
	clientFirebase: IClientFirebase
): IUserAuthPayload {
	console.log("getUserPayload() user, profile:", user, profile);
	let uid: string;
	let email: string;
	let displayName: string;
	if (!user) return null;

	uid = user.uid;
	email = user.email;
	displayName = user.displayName;
	if (!displayName) {
		displayName = profile?.first_name + " " + profile?.last_name;
	}
	let userPayload: IUserAuthPayload = {
		uid,
		email,
		user,
		profile,
		displayName,
		role: profile?.role,
		superAdmin: profile?.superAdmin,
		psAdmin: profile?.psAdmin,
		psRole: "guest",
		nameInitials: getInitials(profile?.first_name, profile?.last_name),
	};
	userPayload.psRole = getPSRole(
		{ uid, isAuthenticated: true, userPayload, tenant: profile?.tenant },
		clientFirebase
	);
	return userPayload;
}

export async function loadUserPayload(
	user: firebase.User,
	firebase,
	allowSubscribers: boolean = false
): Promise<IUserAuthPayload> {
	console.log("loadUserPayload() user:", user);
	let uid: string;
	let email: string;
	let displayName: string;
	if (!user) return null;

	uid = user.uid;
	email = user.email;
	displayName = user.displayName;

	const profile: IUserProfileData = await getUserProfile(uid, firebase, allowSubscribers);
	if (!profile) return null;
	if (!displayName) {
		displayName = profile.first_name + " " + profile.last_name;
	}

	let userPayload: IUserAuthPayload = {
		uid,
		// loggedIn: true,
		email,
		user,
		profile,
		displayName,
		role: profile.role,
		superAdmin: profile.superAdmin,
		psAdmin: profile.psAdmin,
		psRole: "guest",
		nameInitials: getInitials(profile.first_name, profile.last_name),
	};
	userPayload.psRole = getPSRole(
		{ uid, isAuthenticated: true, userPayload, tenant: profile?.tenant },
		null // simba
	);
	return userPayload;
}

export async function getUserProfile(uid: string, firebase, allowSubscribers?: boolean): Promise<IUserProfileData> {
	const user: any = await firebase
		.firestore()
		.collection("users")
		.doc(uid)
		.get()
		.catch((err) => {
			console.log("Error getting user data:", err);
			throw new Error(err);
		});
	const profile: IUserProfileData = user.data();
	console.log("profile:", profile);
	if (!profile) {
		console.debug("firebase api:", firebase);
		throw new Error("No profile data for user");
	}
	if (!profile.active && !allowSubscribers) {
		throw new Error("User is blocked");
	}
	// firebase.auth().tenantId = profile.tenant;
	return profile;
}

export async function updateUserProfile(
	uid: string,
	firebase,
	profileData: Partial<IUserProfileData>,
	password?: string,
	skipUserUpdate?: boolean,
	firestore?
): Promise<IUserAuthPayload> {
	console.log("updateUserProfile(), profileData:", profileData);
	console.log("firestore:", firestore);
	const user: firebase.User = firebase.auth ? firebase.auth().currentUser : null;
	const userPrevEmail: string = user ? user.email : "";
	const userRef = (firestore || firebase.firestore()).collection("users").doc(uid);
	if (profileData.primary_email && !skipUserUpdate && profileData.primary_email !== userPrevEmail) {
		if (password) {
			// reauthenticate request
			const userCredential = await firebase.auth().signInWithEmailAndPassword(user.email, password);
			await userCredential.user.updateEmail(profileData.primary_email);
			if (!userCredential.user.emailVerified) await userCredential.user.sendEmailVerification();
			// const cred = firebase
			//   .auth()
			//   .EmailAuthProvider.credential(user.email, password);
			// await user.reauthenticateWithCredential(cred);
		} else {
			await user.updateEmail(profileData.primary_email).catch((err) => {
				console.log(err);
				throw err;
			});
			if (!user.emailVerified) await user.sendEmailVerification();
		}
	}
	delete profileData["password"];
	await userRef.set(profileData, { merge: true }).catch((err) => {
		console.log("Error updating user data:", err);
		throw new Error(err);
	});
	let updatedUser = await userRef.get();
	let profile: IUserProfileData = updatedUser.data();
	if (!profile) {
		throw new Error("No profile data for user");
	}
	if (profileData.primary_email && profileData.primary_email !== userPrevEmail) {
		// email was updated
		profile.prev_emails = profile.prev_emails || [];
		const found = profile.prev_emails.find((email: string) => email === profileData.primary_email);
		if (!found) profile.prev_emails.push(userPrevEmail);
		updatedUser = await userRef.set(profile, { merge: true }).catch((err) => {
			console.log("Error updating user data:", err);
			throw new Error(err);
		});
	}
	console.log("profile:", profile);
	// update user display name
	if ((profileData.first_name || profileData.last_name) && !skipUserUpdate) {
		const updatedDisplayName: string = `${profile.first_name} ${profile.last_name}`;
		await user
			.updateProfile({
				displayName: updatedDisplayName,
				// photoURL: "https://example.com/jane-q-user/profile.jpg"
			})
			.catch((error) => {
				throw new Error("Error updating user display name");
			});
	}

	return getUserPayload(user, profile, null);
}

export function getInitials(fname: string, lname: string): string {
	const f = fname && fname.length ? fname.substr(0, 1) : "";
	const l = lname && lname.length ? lname.substr(0, 1) : "";
	return f + l;
}

export async function loginServiceUser(firebaseApp) {
	if (firebaseApp.auth().currentUser) return Promise.resolve(firebaseApp.auth().currentUser);
	return (
		firebaseApp
			.auth()
			.signInWithEmailAndPassword(process.env.REACT_APP_SERVICE_USER, process.env.REACT_APP_SERVICE_USER_PASS)
			// .signInAnonymously()
			.then((user) => {
				console.log("SIGNED IN WITH SERVICE USER", user);
				return user;
			})
	);
}
