import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { IStream } from "../../models/stream";
import { IAuthContext } from "../../laco-common/base/auth";

const COLLECTION: string = "site_streams";

export enum StreamActionTypes {
	LOAD_STREAMS = "[stream] LOAD_STREAMS",
}

export const loadStreams = (authContext?: IAuthContext): AppThunkResult<Promise<any>> => async (
	dispatch: AppThunkDispatch,
	getState: IGetState,
	appContext: IAppContext
) => {
	let streams: IStream[] = [];
	// if (authContext && authContext.isAuthenticated) { // dana sheri TODO
	streams = await getCollection<IStream>(
		appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
		COLLECTION,
		"stream_date",
		(query) => query.where("active", "==", true),
		true
	);
	// }
	dispatch({
		type: StreamActionTypes.LOAD_STREAMS,
		payload: streams,
	});
	return streams;
};

export const mapStreamDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadStreams: (authContext?: IAuthContext) => dispatch(loadStreams(authContext)),
});

export type IStreamActionDispatches = ReturnType<typeof mapStreamDispatchToProps>;
