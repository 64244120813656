import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapSubscriptionStateToProps } from "../reducers/subscription";
import { addUpdateDocumentInCollection, getDocument } from "../../laco-common/base/firestore-utils";
import { IOrder, isPaymentStatusComplete, IProduct } from "../../components/ecwid/StoreContext";
import { IMembershipDetails, getMembershipDetails, BWMembershipStatus } from "../../models/bwMembership";
import { convertObjectToFirebaseObj } from "../../utils";
import NotificationsService from "../../laco-common/services/notifications";
import { IAuthContext } from "../../laco-common/base/auth";

export enum SubscriptionActionTypes {
	LOAD_SUBSCRIPTION = "[subscription] LOAD_SUBSCRIPTION",
	ACTIVATE_SUBSCRIPTION = "[subscription] ACTIVATE_SUBSCRIPTION",
}

export const loadSubscription =
	(authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const membership: IMembershipDetails = getMembershipDetails(authContext.userPayload);
		dispatch({
			type: SubscriptionActionTypes.LOAD_SUBSCRIPTION,
			payload: membership,
		});
		return membership;
	};

export const onOrderComplete =
	(order: IOrder, authContext: IAuthContext, skipSubscription?: boolean): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const today: Date = new Date();
		let endDate: Date = new Date();
		endDate.setFullYear(endDate.getFullYear() + 1, endDate.getMonth(), endDate.getDate() + 1);
		console.log("onOrderComplete subscription end date:", endDate);
		if (!isPaymentStatusComplete(order)) return;
		const email: string = order.customer?.email || authContext.userPayload?.email;

		if (!skipSubscription) {
			await authContext.updateUserProfile({
				isBW_member: true,
				subscriptionUpdatedOn: today.getTime(),
				subscriptionEndDate: endDate.getTime(),
			});
		}
		const orderData: Partial<IOrder> & { productIds?: any } = convertObjectToFirebaseObj(order);
		orderData.productIds = order.items.map((item) => item.product.id).toString();

		await addUpdateDocumentInCollection(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"orders",
			orderData,
			`${authContext.userPayload?.uid || email}-${order.orderNumber}`
		);

		if (!skipSubscription) {
			const membership: IMembershipDetails = {
				...getMembershipDetails(authContext.userPayload),
				status: BWMembershipStatus.ACTIVE,
				updatedOn: today,
				validThrough: endDate,
			};

			NotificationsService.success(
				`Order #${order.orderNumber} processed successfully. Your membership is now active.`,
				undefined,
				{
					autoHideDuration: 9999,
				}
			);

			dispatch({
				type: SubscriptionActionTypes.ACTIVATE_SUBSCRIPTION,
				payload: membership,
			});
			return membership;
		}

		return null;
	};

export const updateFormSubmissionToPaid =
	(order: IOrder, formId: string, authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const email: string = order.customer?.email || authContext.userPayload?.email;
		// find latest form submission with email + form id
		const querySnapshotSubmissions = await (
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase
		)
			.getFirebase()
			?.firestore()
			.collection("form_submissions")
			.where("formId", "==", formId)
			.where("user-email", "==", email)
			.orderBy("created_at", "desc")
			.get();

		if (querySnapshotSubmissions.docs.length) {
			const lastSubmission = querySnapshotSubmissions.docs[0];
			console.log("lastSubmission:", lastSubmission);
			const formSubmissionId: string = lastSubmission.id;
			await addUpdateDocumentInCollection(
				appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
				"form_submissions",
				{ paid: true, ecwidOrderNo: order.orderNumber },
				formSubmissionId
			);
		}
	};

export const mapSubscriptionDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadSubscription: (authContext: IAuthContext) => dispatch(loadSubscription(authContext)),
	onOrderComplete: (order: IOrder, authContext: IAuthContext, skipSubscription?: boolean) =>
		dispatch(onOrderComplete(order, authContext, skipSubscription)),
	updateFormSubmissionToPaid: (order: IOrder, formId: string, authContext: IAuthContext) =>
		dispatch(updateFormSubmissionToPaid(order, formId, authContext)),
});

export type ISubscriptionActionDispatches = ReturnType<typeof mapSubscriptionDispatchToProps>;
export type ISubscriptionStoreProps = ReturnType<typeof mapSubscriptionStateToProps> &
	ReturnType<typeof mapSubscriptionDispatchToProps>;
