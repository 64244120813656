import { createMuiTheme, createStyles } from "@material-ui/core/styles";
// import theme from "../laco-common/theme";

//  https://material-ui.com/customization/default-theme/?expand-path=$.palette.secondary
// https://material-ui.com/customization/default-theme/
// https://material-ui.com/customization/globals/

// Color Palette:
// https://material-ui.com/customization/color/

// $black: #212A30;
// $blue: #2C4479 !default;
// $red: #CD2822;
// $orange: $primary;
// $yellow: #DD9F00;
// $green: #1E5435;

const black = "#212A30";
// const dark = "rgba(14, 11, 9, 0.95)";
const gray = "#747980";
const gray500 = "#adb5bd";
const gray400 = "#ced4da";
const fontSize = "0.8rem";
const primary = process.env.REACT_APP_PRIMARY_COLOR;
// const black2 = "rgba(14, 11, 9, 0.95)";
const green = "#1E5435";
const red = "#CD2822";
const yellow = "#DD9F00";
const blue = "#2C4479";

export const THEME_COLORS = {
	white: "#FFFFFF",
	black,
	gray,
	gray500,
	gray400,
	green,
	blue,
	red,
	yellow,
};

export const FONT_FAMILY = '"Montserrat", sans-serif';

export default createMuiTheme({
	palette: {
		primary: {
			main: primary, // main orange
		},
		secondary: {
			main: "#ffffff", // blue #1968af
		},
		success: {
			main: green, // green #0ba168 rgb(11,161,104)
		},
		error: {
			main: red, // red #ee1111 // rgb(238,17,17)
		},
		info: {
			main: blue, // ff6f2f
		},
		warning: {
			main: yellow, // yellow #ee9211 rgb(238,146,17)
		},
	},
	typography: {
		fontFamily: ["Montserrat", "sans-serif"].join(","),
		// fontSize,
		button: {
			textTransform: "capitalize",
			fontSize,
		},
	},
	// input: {
	// 	// border: "1px solid green",
	// 	// fontSize: "12px !important",
	// 	// transition: theme.transitions.create(["border-color", "box-shadow"]),
	// },
	shape: {
		borderRadius: 10,
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 576,
			md: 769,
			lg: 992,
			xl: 1200,
		},
	},
	overrides: {
		MuiList: createStyles({
			root: {
				fontSize,
				// backgroundColor: dark,
				// color: "var(--color-contrast-text)",
				// padding: 0,
			},
		}),
		MuiMenuItem: createStyles({
			root: {
				fontSize,
				// backgroundColor: dark,
				"&:hover": {
					backgroundColor: "var(--color-contrast-bg-hover)",
				},
			},
		}),
		// MuiListItem: createStyles({
		// 	root: {
		// 		fontSize,
		// 		padding: "0px 3px !important",
		// 		minHeight: "30px !important",
		// 		// padding: "10px 20px !important",
		// 	},
		// }),
		MuiInput: createStyles({
			root: {
				// borderWidth: 0,
				// fontSize: "12px !important",
				// borderColor: "#747980",
				// borderBottom: "solid 1px #0E0B09", // black
				// "& label.Mui-focused": {
				// 	color: "green",
				// },
				// "& .MuiInput-underline:after": {
				// 	borderBottomColor: "green",
				// },
				// MuiOutlinedInput-notchedOutline
				// "& .MuiOutlinedInput-notchedOutline": {
				// 	"& fieldset": {
				// 		borderColor: "red",
				// 	},
				// 	"&:hover fieldset": {
				// 		borderColor: "yellow",
				// 	},
				// 	"&.Mui-focused fieldset": {
				// 		borderColor: "green",
				// 	},
				// },
			},
		}),
		MuiIconButton: createStyles({
			root: {
				// color: "pink",
				// "&:hover": {
				// 	color: gray,
				// },
				"&.active": {
					color: gray,
				},
			},
		}),
		MuiFormLabel: createStyles({
			root: {
				fontSize,
				fontWeight: 700, // 500
				color: black,
			},
		}),
		MuiOutlinedInput: {
			root: {
				// backgroundColor: "rgba(255,255,255,0.8)",
				// "&:hover": {
				// 	backgroundColor: "rgba(255,255,255,1)",
				// },
				// "&.Mui-focused": {
				// 	backgroundColor: "rgba(255,255,255,1)",
				// 	borderColor: "pink !important",
				// },
				fieldset: {
					// "&.MuiOutlinedInput-notchedOutline": {
					// 	borderBottomColor: "yellow !important",
					// },
				},
			},
		},
	},
});

// overrides: {
// 	MuiInput: {
// 		focused: {
// 			color: '#ffa000',
// 		},
// 		inkbar: {
// 			'&:after': {
// 				backgroundColor: '#ffa000',
// 			},
// 		},
// 	},
// 	MuiFormLabel: {
// 		focused: {
// 			color: '#ffa000',
// 		},
// 	},
// },

// const theme = createMuiTheme({
// 	palette: {
// 	  primary: {
// 		light: '#757ce8',
// 		main: '#3f50b5',
// 		dark: '#002884',
// 		contrastText: '#fff',
// 	  },
// 	  secondary: {
// 		light: '#ff7961',
// 		main: '#f44336',
// 		dark: '#ba000d',
// 		contrastText: '#000',
// 	  },
// 	},
//   });

// MuiFilledInput: {
// 	root: {
// 	  backgroundColor: 'rgba(255,255,255,0.8)',
// 	  '&:hover': {
// 		backgroundColor: 'rgba(255,255,255,1)'
// 	  },
// 	  '&.Mui-focused': {
// 		backgroundColor: 'rgba(255,255,255,1)'
// 	  }
// 	}
//   }
