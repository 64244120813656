import { IForm, IFormField, ISection, FormListItem } from "../models";
import { IUserReport } from "../models/userReport";
import { IUserAuthPayload } from "../models/user";
import { IFormSubmission } from "../models/formSubmission";

export async function getCollection<T extends { id: string }>(
	clientFirebase,
	collectionName: string,
	displayField?: string,
	filterQuery?: ((query) => any) | string,
	orderDesc?: boolean,
	startAt?: string | number
): Promise<T[]> {
	let items: T[] = [];
	if (!collectionName) return Promise.reject("Collection name can't be empty");
	let query = clientFirebase.getFirebase()?.firestore().collection(collectionName);
	if (filterQuery) {
		let _query;
		if (typeof filterQuery === "string") {
			try {
				_query = eval(filterQuery as string);
			} catch (e) {
				console.error("Error parsing filterQuery:", e);
			}
		} else {
			_query = filterQuery;
		}
		query = _query(query);
	}
	if (displayField) {
		if (startAt) {
			query = query.orderBy(displayField, orderDesc ? "desc" : "asc").startAt(startAt);
		} else {
			query = query.orderBy(displayField, orderDesc ? "desc" : "asc");
		}
	}
	const querySnapshot = await query.get();
	querySnapshot.forEach((doc) => {
		const data: any = doc.data();
		items.push({ id: doc.id, ...data });
	});
	console.log("Items retrieved from collection:", collectionName, items);
	return items;
}

export async function getDocument<T>(clientFirebase, collectionName: string, id: string): Promise<T> {
	if (!collectionName) return Promise.reject("Collection name can't be empty");
	let query = clientFirebase.getFirebase()?.firestore().collection(collectionName).doc(id);

	const docSnapshot = await query.get().catch((err) => {
		console.log("Error getting document:", err);
		throw new Error(err);
	});
	const data: any = docSnapshot.data();
	console.log("Doc retrieved from collection:", collectionName, data);
	return data;
}

export async function addUpdateDocumentInCollection<T>(
	clientFirebase,
	collectionName: string,
	data: T,
	docId?: string,
	dontMerge?: boolean
): Promise<T> {
	const newItem = { ...data, created_at: Date.now() };
	let item: T;
	if (docId) {
		item = await clientFirebase
			.getFirebase()
			?.firestore()
			.collection(collectionName)
			.doc(docId)
			.set(newItem, { merge: !dontMerge });
	} else {
		item = await clientFirebase.getFirebase()?.firestore().collection(collectionName).add(newItem);
	}
	console.log("Items added to collection:", collectionName, item);
	return item;
}

export async function deleteDocument(clientFirebase, collectionName: string, docId: string): Promise<any> {
	if (!collectionName) return Promise.reject("Collection name can't be empty");
	await clientFirebase.getFirebase()?.firestore().collection(collectionName).doc(docId).delete();
	console.log("Item deleted:", collectionName, docId);
}

export async function contactAgent(clientFirebase, userReport: IUserReport): Promise<any> {
	return addUpdateDocumentInCollection<IUserReport>(clientFirebase, "user_reports", userReport);
}

export async function getForm(formId: string, firebase): Promise<IForm> {
	if (!formId) throw Error("getForm(): No formId");
	const docSnapshot = await firebase.firestore().collection("forms").doc(formId).get();
	let data = docSnapshot.data();
	if (!data || !data.name) {
		console.log("No form by id:", formId);
		return Promise.reject();
	}
	const form: IForm = {
		id: docSnapshot.id,
		...data,
		sections: [],
		fields: [],
		fieldsMap: {},
	};

	// load sections
	const querySnapshotSections = await firebase
		.firestore()
		.collection("forms")
		.doc(formId)
		.collection("sections")
		.where("active", "==", true)
		.orderBy("order", "asc")
		.get();
	querySnapshotSections.forEach((doc: any) => {
		const data = doc.data();
		form.sections.push({
			id: doc.id,
			...data,
			fields: [],
		});
	});

	// load form fields
	const querySnapshotFields = await firebase
		.firestore()
		.collection("forms")
		.doc(formId)
		.collection("fields")
		.orderBy("order", "asc")
		.get();
	querySnapshotFields.forEach((doc: any) => {
		const data = doc.data();
		const formField: IFormField = {
			id: doc.id,
			...data,
		};
		if (!formField.section) {
			// no section, push to general
			form.fields.push(formField);
			if (form.fieldsMap[formField.id]) {
				console.log("Overriding field", formField.id);
			}
			form.fieldsMap[formField.id] = formField;
		} else {
			// find relevant section
			const sectionIndex: number = form.sections.findIndex((s: ISection) => s.id === formField.section);
			if (sectionIndex < 0) {
				console.log("Invalid section id:", formField.section);
			} else {
				form.sections[sectionIndex].fields.push(formField);
				if (form.fieldsMap[formField.id]) {
					console.log("Overriding field", formField.id);
				}
				form.fieldsMap[formField.id] = formField;
			}
		}
	});
	console.log("form retrieved:", form);
	return Promise.resolve(form);
}

export function createMonthlyReport(
	formId: string,
	values: any,
	formConfig: IForm,
	userPayload: IUserAuthPayload,
	originalValues
): { [key: string]: any } {
	console.log("createMonthlyReport:", values, formConfig, originalValues);
	let report = {
		...originalValues,
		uid: userPayload?.uid,
		"user-email": userPayload?.email,
		"user-name": userPayload?.displayName,
	};

	for (let key in report) {
		const fieldValues = report[key];
		if (Array.isArray(fieldValues)) {
			const items: FormListItem[] = fieldValues;
			const valuesSelected = [];
			let hasForm: boolean = false;
			items.forEach((item: FormListItem) => {
				if (item.checked) {
					if (item.form) {
						item.form.getOrderedFields().forEach((field) => {
							report[`${key}-${item.value}-${field.key}`] = Number(field.value);
						});
						hasForm = true;
					} else {
						valuesSelected.push(item.value);
						hasForm = false;
					}
				}
			});
			if (hasForm) {
				report[key] = values[key].join(", ");
			} else {
				report[key] = valuesSelected.join(", ");
			}
		}
	}

	return report;
}

export async function getFormSubmissions(
	firebaseContext,
	isAdmin?: boolean,
	uids?: string[],
	formId?: string
): Promise<IFormSubmission[]> {
	if (uids && !isAdmin) {
		const promises = [];
		uids.forEach((uid: string) => {
			promises.push(
				getCollection<IFormSubmission>(
					firebaseContext.clientFirebase,
					"form_submissions",
					"created_at",
					(query) => {
						return query.where("uid", "==", uid);
					},
					true
				)
			);
		});
		await Promise.all(promises).then(() => {});
	}
	const formSubmissions: IFormSubmission[] = await getCollection<IFormSubmission>(
		firebaseContext.clientFirebase,
		"form_submissions",
		"created_at",
		(query) => {
			if (isAdmin) {
				if (formId) {
					return query.where("formId", "==", formId);
				}
				return query;
			}
			if (formId) {
				return query.where("formId", "==", formId).where("uid", "==", firebaseContext.userPayload.uid);
			}
			return query.where("uid", "==", firebaseContext.userPayload.uid);
		},
		true
	);
	console.log("formSubmissions:", formSubmissions);
	return formSubmissions;
}
