import { IAppContext } from "../../contexts/app/AppContext";
import { AppThunkDispatch, AppThunkResult } from ".";
import { IGetState } from "../reducers";
import { mapDirectorStateToProps } from "../reducers/director";
import { getCollection } from "../../laco-common/base/firestore-utils";
import { IDirector } from "../../laco-common/base/models/director";
import { sortBy } from "../../utils";

export enum DirectorActionTypes {
	LOAD_DIRECTORS = "[director] LOAD_DIRECTORS",
}

export const loadDirectors =
	(): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const directors: IDirector[] = await getCollection<IDirector>(
			appContext.firebase.secondClientFirebase || appContext.firebase.clientFirebase,
			"directors",
			undefined,
			(query) => query.where("active", "==", true)
		);
		dispatch({
			type: DirectorActionTypes.LOAD_DIRECTORS,
			payload: sortBy(directors || [], "assignment"),
		});
		return directors;
	};

export const mapDirectorDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadDirectors: () => dispatch(loadDirectors()),
});

export type IDirectorActionDispatches = ReturnType<typeof mapDirectorDispatchToProps>;
export type IDirectorStoreProps = ReturnType<typeof mapDirectorStateToProps> &
	ReturnType<typeof mapDirectorDispatchToProps>;
