import { AppThunkResult, AppThunkDispatch } from "./index";
import { IAppContext } from "../../contexts/app/AppContext";
import { IGetState } from "../reducers";
import { getCollection, getForm } from "../../laco-common/base/firestore-utils";
import { IForm } from "../../laco-common";
import { IFormPage, IPage } from "../../laco-common/base/models/page";
import { IAuthContext } from "../../laco-common/base/auth";
import { firewireApp } from "../../firewire";
import { WidgetType } from "../../laco-common/base/models/widget";

const COLLECTION: string = "forms";

export enum FormActionTypes {
	LOAD_FORMS = "[form] LOAD_FORMS",
	LOAD_PS_FORMS = "[form] LOAD_PS_FORMS",
}

export const loadForms =
	(authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const showInactive: boolean = !!authContext.userPayload && authContext.userPayload.role === "admin";
		const q = showInactive ? undefined : (query) => query.where("active", "==", true);
		const forms: IForm[] = await getCollection<IForm>(appContext.firebase.clientFirebase, COLLECTION, "order", q);
		const pagesWForms: IPage[] = getState().appStore.pages.filter(
			(p: IPage) =>
				!!p.formId &&
				(showInactive ? true : p.active) &&
				(((p.widgets || []) as WidgetType[]).includes("form") ||
					((p.widgets || []) as WidgetType[]).includes("form-content-page") ||
					((p.widgets || []) as WidgetType[]).includes("form-content-page2"))
		);
		const formPages: IFormPage[] = (pagesWForms || [])
			.map((p) => {
				const formIds: string[] = Array.isArray(p.formId) ? p.formId : p.formId ? [p.formId] : [];
				if (!formIds || !formIds.length) return null;
				const _forms: IForm[] = forms.filter((f) => formIds.includes(f.id));
				if (!_forms?.length) return null;
				return { ...p, forms: _forms };
			})
			.filter((p) => p !== null);
		console.log("forms, formPages:", forms, formPages);
		// forms = forms.filter(
		// 	(f: IForm) =>
		// 		!formPages.find((p: IPage) => (Array.isArray(p.formId) ? p.formId.includes(f.id) : p.formId === f.id))
		// );
		dispatch({
			type: FormActionTypes.LOAD_FORMS,
			payload: { forms, formPages },
		});
		return forms;
	};

export const loadPSForms =
	(authContext: IAuthContext): AppThunkResult<Promise<any>> =>
	async (dispatch: AppThunkDispatch, getState: IGetState, appContext: IAppContext) => {
		const q =
			authContext.userPayload && authContext.userPayload.role === "admin"
				? undefined
				: (query) => query.where("active", "==", true);
		let forms: IForm[] = await getCollection<IForm>(firewireApp, COLLECTION, "order", q);
		dispatch({
			type: FormActionTypes.LOAD_PS_FORMS,
			payload: { forms },
		});
		return forms;
	};

export const mapFormDispatchToProps = (dispatch: AppThunkDispatch) => ({
	loadForms: (authContext: IAuthContext) => dispatch(loadForms(authContext)),
	loadPSForms: (authContext: IAuthContext) => dispatch(loadPSForms(authContext)),
});

export type IFormActionDispatches = ReturnType<typeof mapFormDispatchToProps>;
